export default function ({ store, redirect, app }) {
  if (store.state.auth.loggedIn && store.state.auth.user.jobseeker_profile) {
    if (!store.state.auth.user.referrer_profile) {
      return redirect(
        app.localePath({
          name: 'referrer-profile-id',
          params: { id: 'me' },
          query: { tab: 'my-referees' }
        })
      )
    } else {
      return redirect(app.localePath({ name: 'index' }))
    }
  }
}
