<template>
  <div @mouseenter="hover = true" @mouseleave="hover = false">
    <slot :hover="hover"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: false
    }
  }
}
</script>
