<template>
  <div class="tooltip-box pointer">
    <slot />
    <div
      v-if="from"
      class="tooltip"
      :class="
        screen === 'mobile' ? 'position-mobile-form' : 'position-laptop-form'
      "
    >
      <div class="text is-size-7 has-text-left">{{ $t(text) }}</div>
    </div>
    <div
      v-else
      class="tooltip"
      :class="screen === 'mobile' ? 'position-mobile' : 'position-laptop'"
    >
      <div class="text is-size-7 has-text-left">{{ $t(text) }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    from: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({ screen: 'getScreen' })
  }
}
</script>

<style>
.tooltip-box {
  position: relative;
}

.tooltip-box:hover .tooltip {
  opacity: 1;
}
.tooltip {
  color: #ffffff;
  text-align: center;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 1s;
  position: absolute;
  z-index: 1000 !important;
  background: #4e4d4f;

  left: 100px;
  top: 0;
  width: auto;
  padding: 0.2rem 1rem;
}

@media screen and (max-width: 752px) {
  .position-mobile {
    right: 7rem;
    top: -0.9rem;
    width: 10rem;
  }
}
@media screen and (max-width: 1921px) {
  .position-laptop {
    right: 13rem;
    top: -0.9rem;
    width: max-content;
  }
}

@media screen and (max-width: 768px) {
  .position-mobile-form {
    top: 0.1rem;
    left: 1.6rem;
    width: 8rem;
  }
}
@media screen and (max-width: 2560px) {
  .position-laptop-form {
    top: 0.1rem;
    width: max-content;
    left: 1.5rem;
  }
}
</style>
