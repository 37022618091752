<template>
  <div>
    <div
      :class="{ 'is-active': displayAdditionalInfo }"
      class="modal modal-z-index"
    >
      <div class="modal-background" @click="$emit('close')"></div>
      <div class="card modal-width modal-border modal-active">
        <header class="container modal-header-shadow card-header">
          <div
            class="column overflow-auto height-auto is-12 has-padding-left-40-desktop has-padding-right-40-desktop"
          >
            <div class="columns is-multiline is-mobile is-vcentered is-desktop">
              <div class="container column is-11">
                <div class="has-text-weight-bold is-size-5">
                  {{ $t('Additional Info') }}
                </div>
                <div class="is-size-7 has-text-grey">
                  {{ $t('Please fill your initial details to post a job') }}
                </div>
              </div>
              <div class="column is-1 modalbtn has-text-right">
                <span class="pointer" @click="$emit('close')">
                  <vue-icon-base
                    height="14"
                    width="14"
                    :path="$options.icons[$kc('uni-multiply')]"
                  >
                  </vue-icon-base>
                </span>
              </div>
            </div>
          </div>
        </header>
        <section class="modal-card-body py-0 modal-height overflow-auto">
          <additional-info-new
            :footer="false"
            :additionalInfoForm="additionalInfoForm"
            @additionalInfo="additionalInfo"
            @previousStep="gotoPreviousStep"
          ></additional-info-new>
        </section>
        <footer class="container modal-card-foot has-background-white">
          <div class="column has-text-right py-0 mx-4">
            <button
              class="button previous-button-width is-outlined is-employer is-small"
              @click="gotoPreviousStep()"
              v-text="$t('Previous Step')"
            ></button>
            <button
              class="button button-width is-jobseeker has-text-white is-small"
              :disabled="disabled"
              @click="additionalInfoForm = !additionalInfoForm"
              v-text="$t('Preview and Publish')"
            ></button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import AdditionalInfoNew from '@/components/Job/Post/Forms/AdditionalInfoNew.vue'
import { extend } from 'vee-validate'
import VueIconBase from '@/components/Commons/VueIconBase'
import {
  uniEye,
  uniMultiply,
  uniSmileBeam,
  uniMars,
  uniTimes,
  uniBusAlt,
  uniFileAlt,
  uniBuilding,
  uniPostcard,
  uniTagAlt,
  uniEditAlt,
  uniFileCheckAlt,
  uniFileQuestionAlt
} from '@/helpers/icons'
import FormMixin from '@/mixins/veeValidate'
import { genderChoices } from '@/helpers/constants/jobseeker'
export default {
  icons: {
    uniSmileBeam,
    uniEye,
    uniMultiply,
    uniMars,
    uniTimes,
    uniBusAlt,
    uniFileAlt,
    uniBuilding,
    uniPostcard,
    uniTagAlt,
    uniEditAlt,
    uniFileCheckAlt,
    uniFileQuestionAlt
  },
  components: { VueIconBase, AdditionalInfoNew },
  mixins: [FormMixin],
  props: {
    displayAdditionalInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      additionalInfoForm: false,
      disabled: false,
      has_benefits: false,
      hide_org_name: false,
      genderChoices,
      displayQuestionSection: false,
      showQuestionForm: false,
      questionTypes: [
        { value: 'text', text: this.$t('Short Answer') },
        { value: 'textarea', text: this.$t('Multi-line Answer') }
      ],
      question: {
        question_type: 'text',
        question: '',
        is_required: false,
        description: 'a'
      },
      questionToEdit: null,
      addedQuestions: [],
      slug: '',
      loadingQuestions: false,
      formValues: {
        benefits: [],
        org_alt_name: '',
        selectedBenefits: [],
        selectedDocuments: [],
        is_document_required: false,
        is_gender_specific: false,
        required_two_wheeler: false,
        document_categories: [],
        gender: 'Female',
        is_age_specific: false,
        age_unit: 'more',
        min_age: null,
        max_age: null
      }
    }
  },
  watch: {
    has_benefits(val) {
      if (!val) {
        this.formValues.benefits = []
        this.formValues.selectedBenefits = []
      }
    },
    hide_org_name: {
      handler(val) {
        if (!val) {
          this.formValues.org_alt_name = ''
        }
      }
    },
    'formValues.is_document_required': {
      handler(val) {
        if (!val) {
          this.formValues.document_categories = []
          this.formValues.selectedDocuments = []
        }
      },
      immediate: true,
      deep: true
    },
    'formValues.is_age_specific'(val) {
      if (!val) {
        this.formValues.age_unit = 'more'
        this.formValues.age = null
      }
    }
  },
  created() {
    this.addMaxLengthvalidator()
    this.getAddedQuestions()
  },
  methods: {
    additionalInfo() {
      this.submitAdditionalInfo()
    },
    async saveQuestion() {
      if (await this.$refs['question-observer'].validate()) {
        if (this.questionToEdit !== null) {
          this.updateQuestion()
        } else {
          this.submitQuestions()
        }
        this.showQuestionForm = false
      }
    },
    gotoPreviousStep() {
      this.$emit('additionalInfo', {
        formValues: this.formValues
      })
      this.$emit('closeAddForm')
      history.pushState(
        {},
        '',
        this.localePath({
          name: 'job-post',
          query: {
            slug: this.slug,
            step: '2'
          }
        })
      )
      this.$emit('open')
    },
    async saveAndAddQuestion() {
      if (await this.$refs['question-observer'].validate()) {
        this.submitQuestions()
        this.$refs['question-observer'].reset()
      }
    },
    addMaxLengthvalidator() {
      this.slug = document.URL.split('=')[1].split('&')[0]
      extend('max_length_10', {
        validate: value => {
          let validation = true
          validation = validation && value.length < 11
          return validation
        },
        message: `{_field_} ${this.$t('field must be less than or equal 10')}`
      })
    },
    submitAdditionalInfo() {
      this.disabled = true
      this.$router.push(
        this.localePath({
          name: 'job-preview',
          query: {
            slug: this.slug
          }
        })
      )
      this.$emit('closeAddForm')
    },
    populateFormValues(response) {
      if (response.benefits.length) {
        this.has_benefits = true
        this.formValues.benefits = response.benefits
      }
      this.formValues.document_categories = response.document_categories
      if (this.formValues.document_categories.length) {
        this.formValues.is_document_required = true
      }
      this.formValues.required_two_wheeler =
        response.setting.required_two_wheeler
      this.prepareAgeData(response)
      this.formValues.gender = response.setting.gender || 'Female'
      this.formValues.is_gender_specific = response.setting.is_gender_specific
      if (response.org_alt_name) {
        this.hide_org_name = true
      }
      this.formValues.org_alt_name = response.org_alt_name
    },
    prepareAgeData(response) {
      this.formValues.is_age_specific = response.setting.is_age_specific
      this.$set(
        this.formValues,
        'age_unit',
        response.setting.min_age ? 'more' : 'less'
      )
      this.formValues.age = response.setting.min_age || response.setting.max_age
    },
    getAddedQuestions() {
      const endpoint = `/api/v1/job/${this.slug}/questions/`
      this.loadingQuestions = true
      this.$axios
        .$get(endpoint)
        .then(response => {
          this.addedQuestions = response.results
          this.displayQuestionSection = !!response.results.length
        })
        .finally(() => {
          this.loadingQuestions = false
        })
    },
    submitQuestions() {
      const endpoint = `/api/v1/job/${this.slug}/questions/`
      return this.$axios.$post(endpoint, this.question).then(response => {
        this.addedQuestions.push(response)
        this.notifyUser(this.$t('Successfully added question'))
        this.question = {
          question_type: 'text',
          question: '',
          is_required: false,
          description: ''
        }
      })
    },
    updateQuestion() {
      const endpoint = `/api/v1/job/${this.slug}/questions/${this.questionToEdit.id}/`
      this.$axios.$put(endpoint, this.question).then(response => {
        const questionId = this.addedQuestions.findIndex(
          question => question.id === response.id
        )
        this.$set(this.addedQuestions, questionId, response)
        this.questionToEdit = null
        this.notifyUser(this.$t('Successfully updated question'))
        this.question = {
          question_type: 'text',
          question: '',
          is_required: false,
          description: ''
        }
      })
    },
    showUpdateForm(question) {
      this.questionToEdit = question
      this.question = this.deepCopy(question)
      this.showQuestionForm = true
    },
    deleteQuestion(question) {
      const endpoint = `/api/v1/job/${this.slug}/questions/${question.id}/`
      this.$axios.$delete(endpoint).then(response => {
        const questionId = this.addedQuestions.findIndex(
          questionFromLoop => question.id === questionFromLoop.id
        )
        this.addedQuestions.splice(questionId, 1)
        this.notifyUser(this.$t('Successfully deleted question'))
      })
    }
  }
}
</script>
<style scoped>
@media only screen and (max-width: 768px) {
  .modal-width {
    bottom: 0;
    position: absolute;
  }
  .modal-height {
    overflow: auto;
    height: 30rem;
  }
  .modalbtn {
    display: flex;
  }
  .previous-button-width {
    width: 28%;
  }
  .button-width {
    width: 70%;
  }
}
@media only screen and (min-width: 769px) {
  .modal-width {
    position: absolute;
    height: auto;
  }
  .modal-height {
    overflow: auto;
    height: 27rem;
    bottom: 0;
    position: relative;
  }
}

.is-size-8 {
  font-size: 0.85rem;
}
</style>
