export const state = () => ({
  jobPost: { slug: null, detail: {} },
  jobType: ''
})

export const getters = {
  getJobType(state) {
    return state.jobType
  },
  getCurrentJobSlug(state) {
    return state.jobPost.slug
  },
  getCurrentJobDetail(state) {
    return state.jobPost.detail
  }
}

export const mutations = {
  setJobType(state, value) {
    state.jobType = value
  },
  setCurrentJobSlug(state, slug) {
    state.jobPost.slug = slug
  },
  setCurrentJobDetail(state, detail) {
    state.jobPost.detail = detail
  }
}
