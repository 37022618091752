export const state = () => ({
  signUp: { phoneNumber: null, otp: null, org: false }
})

export const getters = {
  currentPhoneNumber(state) {
    return state.signUp.phoneNumber
  },
  currentOtp(state) {
    return state.signUp.otp
  },
  currentOrg(state) {
    return state.signUp.org
  }
}

export const mutations = {
  updatePhoneNumber(state, phoneNumber) {
    state.signUp.phoneNumber = phoneNumber
  },
  updateOtp(state, otp) {
    state.signUp.otp = otp
  },
  updateOrg(state, org) {
    state.signUp.org = org
  }
}
