import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_23514a38 from './schemes/oauth2.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/sign-in","logout":"/","home":"/","callback":"/oauth2/callback/"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/api/v1/auth/get-token/","method":"post","propertyName":"token"},"logout":false,"user":{"url":"/api/v1/users/me/","method":"get","propertyName":false}},"_name":"local"}))

  // facebook
  $auth.registerStrategy('facebook', new scheme_23514a38($auth, {"client_id":"771918183282178","userinfo_endpoint":"/api/v1/users/me/","scope":["public_profile","email"],"token_type":"FbBearer","_name":"facebook","authorization_endpoint":"https://facebook.com/v2.12/dialog/oauth"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
