<template>
  <div class="field mb-0">
    <label v-if="title.length >= 1" class="label">{{ title }}</label>
    <div
      ref="autoCompleteDiv"
      class="mb-0 pb-0 is-full-width mt-1"
      :style="autoCompleteFieldCss"
      :class="(fieldError ? 'red-border' : '', border ? 'border' : '')"
    >
      <div
        :class="from === 'business' ? 'has-background-white' : ''"
        class="px-2 is-flex align-center"
      >
        <div
          v-if="
            !addItemButton &&
            allowMultiple &&
            selectedItems.length &&
            showSelectedItems
          "
        >
          <span
            v-for="(item, index) in selectedItems"
            :key="item[itemText]"
            class="tag is-light mr-1 mt-2"
            :title="$t(item[itemText])"
          >
            {{ truncateChars($t(item[itemText]), 25) }}
            <button
              class="delete is-small pointer"
              @click="removeItem(index)"
            ></button>
          </span>
        </div>
        <div
          v-if="
            allowMultiple &&
            addItemButton &&
            selectedItems.length &&
            showSelectedItems
          "
        >
          <span
            v-for="(item, index) in selectedItems"
            :key="item[itemText]"
            class="tag is-light mr-1 mt-2"
            :title="$t(item[itemText])"
          >
            {{ truncateChars($t(item[itemText]), 25) }}
            <button
              class="delete is-small pointer"
              @click="removeItem(index)"
            ></button>
          </span>
          <span
            v-if="!addItemsEnable"
            class="tag pointer has-text-weight-bold is-light has-text-employer mr-1 my-2"
            @click="addItemsEnable = true"
          >
            <span class="mr-1">{{ $t(`Add ${addItemTitle}`) }}</span>
            <vue-icon-base
              height="16"
              width="16"
              :path="$options.icons[$kc('uni-plus')]"
            >
            </vue-icon-base>
          </span>
        </div>
        <div
          v-else-if="
            addItemButton && allowMultiple && showSelectedItems && allowCreate
          "
        >
          <span
            v-if="!addItemsEnable"
            class="tag pointer is-light has-text-weight-bold has-text-employer mr-1 my-2"
            @click="addItemsEnable = true"
          >
            <span class="mr-1">{{ $t(`Add ${addItemTitle}`) }}</span>
            <vue-icon-base
              height="16"
              width="16"
              :path="$options.icons[$kc('uni-plus')]"
            >
            </vue-icon-base>
          </span>
        </div>
        <div
          v-else-if="
            !allowMultiple && selectedItems.length && showSelectedItems
          "
          @click="filterData(true)"
        >
          <span
            v-for="item in selectedItems"
            :key="item[itemText]"
            class="tag mr-1 is-light"
            :title="$t(item[itemText])"
          >
            {{ truncateChars($t(item[itemText]), 25) }}
          </span>
        </div>
        <input
          v-if="!allowMultiple"
          :value="search"
          :disabled="disabled"
          :class="extraInputClass"
          class="autoCompleteInput input"
          :placeholder="formValue === undefined ? placeholder : ''"
          @focus="initFocus"
          @input="search = $event.target.value"
          @keyup.delete="initDelete"
          @keyup.enter="createItem"
          @click="filterData(true)"
          @blur="showSearchResults = false"
        />
        <vue-icon-base
          v-if="showSearchIcon"
          height="16"
          width="16"
          :path="$options.icons[$kc('uni-search-alt')]"
        >
        </vue-icon-base>
        <vue-icon-base
          v-if="showDropDownIcon"
          height="16"
          width="16"
          :path="$options.icons[$kc('uni-down-arrow')]"
        >
        </vue-icon-base>
      </div>
      <div v-if="showSearchItem">
        <input
          v-if="allowMultiple && !addItemButton"
          :value="search"
          :disabled="disabled"
          :class="extraInputClass"
          class="autoCompleteInput input"
          :placeholder="placeholder"
          @input="search = $event.target.value"
          @keyup.enter="createItem"
          @click="filterData(true)"
          @blur="showSearchResults = false"
        />
      </div>
      <div v-else>
        <input
          v-if="allowMultiple && !addItemButton"
          :value="search"
          :disabled="disabled"
          :class="extraInputClass"
          class="autoCompleteInput input"
          :placeholder="placeholder"
          @focus="initFocus"
          @keyup.delete="initDelete"
          @input="search = $event.target.value"
          @keyup.enter="createItem"
          @click="filterData(true)"
          @blur="showSearchResults = false"
        />
      </div>
      <input
        v-if="allowMultiple && addItemButton && addItemsEnable"
        :value="search"
        :disabled="disabled"
        :class="extraInputClass"
        class="autoCompleteInput input"
        :placeholder="placeholder"
        @focus="initFocus"
        @keyup.delete="initDelete"
        @input="search = $event.target.value"
        @keyup.enter="createItem"
        @click="filterData(true)"
        @blur="showSearchResults = false"
      />
    </div>
    <div
      v-if="
        (filteredData.length && showSearchResults) ||
        (allowCreate && search.length && showAddButton)
      "
      class="dropdown is-active z-index-999 is-full-width is-block"
    >
      <div
        id="dropdown-menu"
        class="dropdown-menu is-full-width pt-0"
        role="menu"
      >
        <div v-if="loading" class="dropdown-content py-0">
          <a class="dropdown-item py-2 break-word has-text-centered">
            Loading .....
          </a>
        </div>
        <div v-else class="dropdown-content py-0">
          <div v-if="limitRequire">
            <a
              v-for="(item, index) in filteredData.slice(0, 10)"
              :key="index"
              class="dropdown-item hover-item py-2 break-word"
              @mousedown.prevent="addItem(item)"
            >
              {{ $t(item[itemText]) }}
            </a>
          </div>
          <div v-else>
            <a
              v-for="(item, index) in filteredData"
              :key="index"
              class="dropdown-item py-2 break-word"
              @mousedown.prevent="addItem(item)"
            >
              {{ $t(item[itemText]) }}
            </a>
          </div>
          <div v-if="addElementOption">
            <a
              v-if="search.length"
              class="dropdown-item py-2 has-text-grey break-word"
              @click="createItem"
            >
              Add
              <span class="has-text-weight-bold">{{ search }}</span>
            </a>
            <hr class="is-marginless" />
          </div>
          <div v-else>
            <div
              v-if="allowCreate && search.length && filteredData.length == 0"
            >
              <a
                class="dropdown-item py-2 has-text-grey break-word"
                @click="createItem"
              >
                Add
                <span class="has-text-weight-bold">{{ search }}</span>
              </a>
              <hr class="is-marginless" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { truncateChars } from '@/helpers/utils/common'
import VueIconBase from '@/components/Commons/VueIconBase'
import { uniPlus, uniSearchAlt, uniDownArrow } from '@/helpers/icons'
export default {
  components: { VueIconBase },
  icons: {
    uniDownArrow,
    uniPlus,
    uniSearchAlt
  },
  props: {
    showDropDownIcon: {
      type: Boolean,
      default: false
    },
    showSearchIcon: {
      type: Boolean,
      default: false
    },
    items: {
      type: [Object, Array],
      default: null
    },
    value: {
      type: [Number, Array, Object, String],
      default: undefined
    },
    from: {
      type: String,
      default: ''
    },
    allowMultiple: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    endpoint: {
      type: String,
      default: ''
    },
    itemText: {
      type: String,
      default: ''
    },
    itemValue: {
      type: String,
      default: ''
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    extraInputClass: {
      type: String,
      required: false,
      default: ''
    },
    showSelectedItems: {
      type: Boolean,
      default: true
    },
    filterBy: {
      type: String,
      required: false,
      default: ''
    },
    limitRequire: {
      type: Boolean,
      default: true
    },
    addItemButton: {
      type: Boolean,
      default: false
    },
    addItemTitle: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true
    },
    showSearchItem: {
      type: Boolean,
      default: false
    },
    addElementOption: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      dataItems: [],
      restrictDeleteForMultipleCase: false,
      selectedItems: [],
      autoCompleteFieldCss: null,
      showSearchResults: false,
      showAddButton: false,
      formValue: undefined,
      filteredData: [],
      allowSearch: true,
      fieldError: false,
      loading: false,
      addItemsEnable: true
    }
  },
  watch: {
    value: {
      handler(val) {
        if (typeof val === 'string' && val.length < 1) {
          this.selectedItems = ''
        }
        this.formValue = val
      },
      immediate: true
    },
    addItemButton: {
      handler(val) {
        if (val) {
          this.addItemsEnable = false
        }
      },
      immediate: true
    },
    formValue: {
      handler(val) {
        this.$emit('change')
        if (val) {
          if (val.constructor === Object) {
            if (val[this.itemValue]) {
              this.selectedItems = [val]
              this.$emit('input', val[this.itemValue])
            }
          } else if (Array.isArray(val) && !!val.length) {
            if (val[0] === undefined || !val.length) return
            if (val[0]?.constructor === Object) {
              this.selectedItems = val
              const values = val.map(item => item[this.itemValue])
              this.$emit('input', values)
            } else if (
              (Number.isInteger(val[0]) || typeof val[0] === 'string') &&
              this.items
            ) {
              const itemPool = this.getUniqueArray([
                ...this.items,
                ...this.dataItems,
                ...this.selectedItems
              ])
              this.selectedItems = itemPool.filter(obj =>
                val
                  .toString()
                  .split(',')
                  .includes(obj[this.itemValue].toString())
              )
              this.$emit('updateSelectedItems', this.selectedItems)
            }
          } else if (Number.isInteger(val) || typeof val === 'string') {
            if (this.items) {
              const itemPool = this.getUniqueArray([
                ...this.items,
                ...this.dataItems
              ])
              if (Array.isArray(this.items)) {
                const selectedObj = itemPool.find(
                  obj => obj[this.itemValue] === val
                )
                if (selectedObj) this.selectedItems = [selectedObj]
              } else {
                this.selectedItems = itemPool.filter(
                  obj => obj[this.itemValue] in val
                )
              }
            }
          } else if (!!this.items && this.items.length) {
            this.selectedItems = this.items.filter(
              obj => obj[this.itemValue] in val
            )
          }
        } else if (val === undefined) {
          this.selectedItems = []
          this.fieldError = false
        }
        this.search = ''
      },
      immediate: true
    },
    search(newVal, oldVal) {
      if (this.showSearchItem) {
        if (newVal.length >= 3) {
          this.emitSearch()
        }
      } else {
        if (!!oldVal && oldVal.length) {
          this.fieldError = false
          this.showAddButton = true
          this.filterData(true)
          if (this.filteredData.length < 3) {
            setTimeout(() => {
              this.allowSearch = !this.allowSearch
            }, 1500)
            this.emitSearch()
          }
        }
        if (newVal.length && !oldVal.length) {
          this.restrictDeleteForMultipleCase = true
        }
        if (
          !newVal.length &&
          oldVal.length &&
          (!this.allowMultiple || (this.allowMultiple && this.addItemButton)) &&
          this.allowCreate
        ) {
          this.showSearchResults = false
        }
      }
    },
    items: {
      handler(val) {
        if (val) this.dataItems = this.dataItems && this.dataItems.concat(val)
        this.filterData()
      },
      immediate: true,
      deep: true
    },
    error(val) {
      this.fieldError = val
    },
    fieldError(val) {
      if (val && this.autoCompleteFieldCss) {
        this.autoCompleteFieldCss.borderColor = 'red'
      }
    },
    selectedItems(val) {
      this.$emit('updateSelectedItems', val)
    }
  },
  methods: {
    truncateChars,
    emitSearch() {
      if (this.allowSearch) {
        this.showSearchResults = true
        const data = {
          search: this.search,
          endpoint: this.endpoint
        }
        this.$emit('search', data)
      }
    },
    createItem() {
      if (this.allowCreate) {
        const obj = this.findItem(this.search, this.dataItems)

        if (!obj) {
          const data = {
            add: this.search,
            endpoint: this.endpoint
          }
          this.$emit('add', data)
        } else {
          this.addItem(obj)
        }
        this.showAddButton = false
        this.addItemsEnable = false
      } else {
        this.$emit('enterKeyOutput')
      }
    },
    addItem(item) {
      this.showAddButton = false
      this.showSearchResults = false

      if (this.allowMultiple) {
        this.selectedItems.push(item)
        let emittingValues = []
        if (Array.isArray(this.value)) {
          emittingValues = [...emittingValues, ...this.value]
        } else if (this.value) {
          emittingValues.push(this.value)
        } else {
          emittingValues = []
        }
        emittingValues.push(item[this.itemValue])
        this.$emit('input', emittingValues)
      } else {
        this.$emit('input', item[this.itemValue])
        this.selectedItems = [item]
      }
      this.addItemsEnable = false
      this.dataItems = this.items && this.items.filter(obj => item !== obj)

      // if (this.endpoint) {
      //   this.dataItems = this.dataItems.filter(obj => item !== obj)
      // } else {
      //   this.dataItems = this.items.filter(obj => item !== obj)
      // }
      this.search = ''
    },
    initAutoCompleteLeave() {
      this.autoCompleteFieldCss = null
      this.showSearchResults = false
    },
    initSearch() {
      if (!this.autoCompleteFieldCss) {
        this.autoCompleteFieldCss = {
          borderColor: '#3273dc',
          boxShadow: '0 0 0 0.125em rgba(50, 115, 220, 0.25)'
        }
      }
    },
    initFocus() {
      if (!this.search) {
        if (!this.findItem(this.search, this.dataItems)) {
          this.emitSearch()
        }
      }
    },
    initDelete() {
      if (
        !this.search.length &&
        this.selectedItems.length &&
        !this.restrictDeleteForMultipleCase
      ) {
        this.removeItem(this.selectedItems.length - 1)
        if (!this.allowMultiple && !this.selectedItems.length) {
          this.$emit('input', '')
        }
      } else if (!this.search && this.restrictDeleteForMultipleCase) {
        this.restrictDeleteForMultipleCase = false
      }
    },
    removeItem(index) {
      const removedItem = this.selectedItems[index]
      this.selectedItems.splice(index, 1)
      if (removedItem) this.dataItems && this.dataItems.push(removedItem)

      if (this.allowMultiple && Array.isArray(this.formValue)) {
        this.formValue = this.formValue.filter(
          item => item.toString() !== removedItem[this.itemValue].toString()
        )
        this.$emit('input', this.formValue)
      }
    },
    filterData(showResults = null) {
      const itemText = this.filterBy ? this.filterBy : this.itemText
      this.loading = true
      if (this.dataItems && this.dataItems.length) {
        this.dataItems = this.getUniqueArray(this.dataItems)
        const dataList = this.dataItems.filter(result =>
          result[itemText === 'name_ne' ? 'name' : itemText]
            .toLowerCase()
            .includes(this.search.toLowerCase())
        )

        this.filteredData = dataList.filter(
          result => result[this.itemValue] !== this.formValue
        )

        if (this.allowMultiple) {
          const value = this.value ? this.value : []
          this.filteredData = dataList.filter(
            result => ![...value].includes(result[this.itemValue])
          )
        } else {
          this.filteredData = dataList.filter(
            result => result[this.itemValue] !== this.formValue
          )
        }
      } else {
        this.filteredData = []
      }
      if (showResults !== null) {
        this.showSearchResults = true
      }
      this.loading = false
    },
    findItem(item, array) {
      const itemText = this.filterBy ? this.filterBy : this.itemText
      return array.find(
        result => result[itemText].toLowerCase() === item.toLowerCase()
      )
    },
    getUniqueArray(array) {
      const itemText = this.filterBy ? this.filterBy : this.itemText
      return Array.from(new Set(array.map(a => a[itemText]))).map(id => {
        return array.find(a => a[itemText] === id)
      })
    }
  }
}
</script>

<style scoped lang="css">
.border {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}

.autoCompleteInput {
  border: none;
  box-shadow: none;
}

.autoCompleteInput:focus {
  border: none;
  box-shadow: none;
}
.red-border {
  border: 1px solid red !important;
}
.hover-item :hover {
  background-color: rgb(165, 37, 37);
}
</style>
