<template>
  <div class="has-background-error-color has-page-height">
    <div
      class="container has-padding-left-20-mobile has-padding-right-20-mobile"
    >
      <div class="is-16 has-padding-top-60">
        <a @click="$router.push('/')">
          <img
            height="190"
            width="190"
            src="@/static/images/brand/rojgari-white.svg"
            alt="rojgari logo"
          />
        </a>
      </div>
      <div
        class="columns has-padding-top-20 is-vcentered has-text-weight-bold has-text-white"
      >
        <div class="column is-7">
          <div class="content is-size-1">
            {{
              $t(
                `${
                  get($options.config, [statusCode, 'warning']) ||
                  `${statusCode} Error`
                }`
              )
            }}
          </div>
          <div class="has-padding-bottom-30 has-text-weight-normal is-size-5">
            {{
              $t(
                `${
                  get($options.config, [statusCode, 'message']) ||
                  `${statusCode} Error`
                }`
              )
            }}
          </div>
          <div class="column is-left-paddingless is-12">
            <div class="field has-addons has-text-centered-mobile">
              <div class="control">
                <input
                  v-model="searchQuery"
                  :placeholder="$t('search')"
                  class="input is-outlined is-medium"
                  @change="gotoJobSearchPage"
                />
              </div>
              <div class="control">
                <a class="button is-medium" @click="gotoJobSearchPage">
                  <vue-icon-base
                    height="16"
                    width="16"
                    :path="$options.icons[$kc('uni-search')]"
                  >
                  </vue-icon-base>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-vcentered is-hidden-mobile is-5">
          <img
            height="400"
            width="400"
            :src="
              $options.config[statusCode]
                ? require(`@/static/images/${statusCode}.svg`)
                : ''
            "
            :alt="`${statusCode} errorImage`"
          />
        </div>
      </div>

      <div class="has-margin-top-50 has-text-centered-mobile">
        <span class="has-margin-right-5">
          <button class="button is-big is-jobseeker" @click="$router.push('/')">
            {{ $t('homepage') }}
          </button>
        </span>
        <span class="">
          <button
            class="button is-big is-error-color is-inverted is-outlined"
            @click="$router.push('/contact')"
          >
            {{ $t('contactUs') }}
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import VueIconBase from '@/components/Commons/VueIconBase'

import {
  uniSearch,
  uniDesktopAltSlash,
  uniLayerGroupSlash
} from '@/helpers/icons'

export default {
  components: {
    VueIconBase
  },
  props: {
    statusCode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      searchQuery: ''
    }
  },
  icons: {
    uniSearch,
    uniDesktopAltSlash,
    uniLayerGroupSlash
  },
  config: {
    403: {
      warning: 'Access Denied',
      headWarning: 'Access Denied',
      message: 'accessDeniedMsg',
      mountedAction: vm => {}
    },
    404: {
      warning: 'Page Not Found',
      headWarning: 'Page Not Found',
      message: 'pageNotFoundMsg',
      mountedAction: vm => {}
    },
    500: {
      warning: 'Something Went Wrong',
      headWarning: 'Something Went Wrong',
      message: 'serverErrorMsg',
      mountedAction: vm => {}
    },
    410: {
      warning: 'Page Removed',
      headWarning: 'Page Removed',
      message: 'pageRemovedMsg',
      mountedAction: vm => {}
    },
    400: {
      message: 'Bad request',
      mountedAction: vm => {}
    }
  },
  layout: 'empty',
  mounted() {
    const mountedAction = this.get(this.$options.config, [
      this.statusCode,
      'mountedAction'
    ])
    if (mountedAction) mountedAction(this)
  },
  methods: {
    errorButtonAction(routeFunc) {
      routeFunc(this)
    },
    gotoJobSearchPage() {
      this.$router.push(
        this.localePath({
          name: 'job-search',
          query: {
            search: this.searchQuery
          }
        })
      )
    }
  },
  head() {
    return {
      title:
        this.get(this.$options.config, [this.statusCode, 'headWarning']) ||
        `Some Error Occured With Status Code ${this.statusCode}`
    }
  }
}
</script>
