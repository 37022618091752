const middleware = {}

middleware['anonymousOrEmployer'] = require('../middleware/anonymousOrEmployer.js')
middleware['anonymousOrEmployer'] = middleware['anonymousOrEmployer'].default || middleware['anonymousOrEmployer']

middleware['anonymousOrJobseeker'] = require('../middleware/anonymousOrJobseeker.js')
middleware['anonymousOrJobseeker'] = middleware['anonymousOrJobseeker'].default || middleware['anonymousOrJobseeker']

middleware['employer'] = require('../middleware/employer.js')
middleware['employer'] = middleware['employer'].default || middleware['employer']

middleware['jobPost'] = require('../middleware/jobPost.js')
middleware['jobPost'] = middleware['jobPost'].default || middleware['jobPost']

middleware['jobseeker'] = require('../middleware/jobseeker.js')
middleware['jobseeker'] = middleware['jobseeker'].default || middleware['jobseeker']

middleware['referrer'] = require('../middleware/referrer.js')
middleware['referrer'] = middleware['referrer'].default || middleware['referrer']

export default middleware
