export const state = () => ({
  signUp: {
    fullName: null,
    phoneNumber: null,
    otp: null,
    countryCode: null,
    countryName: null,
    countryFlag: null,
    email: null
  }
})

export const getters = {
  currentFullName(state) {
    return state.signUp.fullName
  },
  currentPhoneNumber(state) {
    return state.signUp.phoneNumber
  },
  currentOtp(state) {
    return state.signUp.otp
  },
  currentCountryCode(state) {
    const dialCode = state.signUp.countryCode
    return dialCode != null ? dialCode : '+977'
  },
  currentCountryName(state) {
    const country = state.signUp.countryName
    return country != null ? country : 'Nepal'
  },
  currentCountryFlag(state) {
    const flag = state.signUp.countryFlag
    return flag != null
      ? flag
      : 'https://api.rojgari.com/static/country/nepal.svg'
  },
  currentEmail(state) {
    return state.signUp.email
  }
}

export const mutations = {
  updateFullName(state, fullName) {
    state.signUp.fullName = fullName
  },
  updatePhoneNumber(state, phoneNumber) {
    state.signUp.phoneNumber = phoneNumber
  },
  updateOtp(state, otp) {
    state.signUp.otp = otp
  },
  updateCountryCode(state, countryCode) {
    state.signUp.countryCode = countryCode
  },
  updateCountryName(state, countryName) {
    state.signUp.countryName = countryName
  },
  updateCountryFlag(state, countryFlag) {
    state.signUp.countryFlag = countryFlag
  },
  updateEmail(state, email) {
    state.signUp.email = email
  }
}
