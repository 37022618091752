import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode
} from 'vee-validate'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import * as rules from 'vee-validate/dist/rules'
import ne from 'vee-validate/dist/locale/ne.json'
import en from 'vee-validate/dist/locale/en.json'

export default {
  data() {
    return {
      currentIndex: 0,
      nonFieldErrors: [],
      fieldErrors: {}
    }
  },
  components: { ValidationObserver, ValidationProvider },
  created() {
    this.initializeVeeValidate()
  },
  methods: {
    initializeVeeValidate() {
      setInteractionMode('lazy')
      const locale = this.$i18n.locale === 'en' ? en : ne
      for (const rule in rules) {
        extend(rule, {
          ...rules[rule] /* eslint-disable-line */,
          message: locale.messages[rule]
        })
      }
    },
    getError(error) {
      if (error !== undefined) {
        this.fieldErrors = {}
        this.nonFieldErrors = []
        const errorResponse =
          error && error.response && error.response.data && error.response.data
        for (const errorField in errorResponse) {
          if (Array.isArray(errorResponse[errorField])) {
            this.getErrorsFromArray(errorResponse, errorField)
          } else if (typeof errorResponse[errorField] === 'string') {
            this.displayErrors(errorField, errorResponse[errorField])
          } else {
            this.getErrorsFromNestedObject(errorResponse, errorField)
          }
        }
      }
    },
    getErrorsFromArray(errorResponse, errorField) {
      let errorMessage = ''
      const errorCollection = errorResponse[errorField]
      for (const i in errorCollection) {
        errorMessage = errorCollection[i]
        if (!isEmpty(errorMessage)) {
          if (typeof errorMessage === 'object') {
            this.getError(errorMessage)
          } else {
            this.displayErrors(errorField, errorMessage)
          }
        }
        if (
          this.incrementIndex &&
          isEqual(errorResponse, this.mainErrorCollection)
        )
          this.currentIndex = this.currentIndex + 1
      }
    },
    getErrorsFromNestedObject(errorResponse, errorField) {
      for (const errorFieldInner in errorResponse[errorField]) {
        let fieldName = ''
        if (typeof parseInt(errorField) !== 'number') {
          fieldName = `${errorField}.${errorFieldInner}`
        } else {
          fieldName = errorFieldInner
        }
        if (typeof errorResponse[errorField][errorFieldInner] === 'string') {
          this.displayErrors(
            fieldName,
            errorResponse[errorField][errorFieldInner]
          )
        } else {
          const errorMessage = errorResponse[errorField][errorFieldInner][0]
          if (
            typeof errorMessage === 'object' &&
            (isEmpty(errorMessage) || Object.keys(errorMessage).length > 0)
          )
            continue
          this.displayErrors(fieldName, errorMessage)
        }
      }
    },
    displayErrors(fieldName, errorMessage) {
      if (this.incrementIndex && fieldName !== 'non_field_errors') {
        fieldName = fieldName + this.currentIndex
        this.addNonFieldValidation(fieldName, errorMessage)
        return
      }
      this.addNonFieldValidation(fieldName, errorMessage)
    },
    addNonFieldValidation(field, msg) {
      const errorObj = {}
      if (!msg) {
        return
      }
      if (
        field !== 'non_field_errors' &&
        field !== 'non-field-errors' &&
        field !== ''
      ) {
        this.$set(this.fieldErrors, field, msg)
      } else {
        errorObj[field.split('_').join(' ')] = {
          field: field.split('_').join(' '),
          message: msg
        }
        this.nonFieldErrors.push(errorObj)
      }
    },
    validateAllFields() {
      if (this.$refs.observer && this.$refs.observer.validate) {
        return this.$refs.observer.validate()
      }
    },
    resetAllFields() {
      return this.$refs.observer.reset()
    },
    veeValidate(fieldName, errors) {
      return {
        id: 'id_' + fieldName,
        errors,
        'error-messages': errors
      }
    }
  }
}
