<template>
  <div>
    <div
      :class="from === 'training' ? 'has-background-white' : ''"
      class="pell-editor pell"
    ></div>
  </div>
</template>

<script>
import { init, exec } from 'pell'
import 'pell/src/pell.scss'
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: this.value,
      editor: null
    }
  },
  watch: {
    content(val) {
      this.$emit('input', val)
    },
    value(val) {
      if (this.editor && !val) {
        this.editor.content.innerHTML = ''
      }
    }
  },
  mounted() {
    this.editor = init({
      element: this.$el.querySelector('.pell-editor'),
      onChange: html => {
        this.content = html
      },
      defaultParagraphSeparator: 'p',
      styleWithCSS: true,
      actions: [
        'bold',
        'italic',
        'underline',
        'heading1',
        'heading2',
        'paragraph',
        'olist',
        'ulist',
        'line',
        {
          name: 'link',
          result: () => {
            const url = window.prompt('Enter the link URL')
            if (url) exec('createLink', this.ensureHTTP(url))
          }
        }
      ],
      classes: {
        content: 'pell-content-custom'
      }
    })
    this.editor.content.innerHTML = this.value
  },
  methods: {
    ensureHTTP: str => (/^https?:\/\//.test(str) && str) || `http://${str}`
  }
}
</script>

<style>
.pell-content-custom {
  height: 100%;
  padding: 35px;
}
</style>
