<template>
  <div>
    <div
      v-if="snackBar.display"
      :class="snackBar.color"
      class="snackbar notification"
    >
      {{ snackBar.text }}
      <button class="delete" @click="hideSnackbar"></button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      snackBar: 'getSnackbar'
    })
  },
  watch: {
    'snackBar.display'(val) {
      const hideSnackbar = this.hideSnackbar
      if (this.snackBar.display) setTimeout(hideSnackbar, 5000)
    },
    immediate: true,
    deep: true
  },
  methods: {
    ...mapMutations(['hideSnackbar'])
  }
}
</script>

<style scoped>
.snackbar {
  width: 400px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10000;
}

@media only screen and (max-width: 500px) {
  .snackbar {
    width: 95%;
  }
}
</style>
