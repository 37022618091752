import { storePrefix } from '@/helpers/constants/shoppingCart'

export const state = () => ({
  employerJobseekerShortlist: [],
  showAlertMessage: false
})

export const mutations = {
  syncFromStore(state, payload) {
    state.employerJobseekerShortlist = payload
  },
  addToEmployerJobseekerShortlist(state, payload) {
    state.employerJobseekerShortlist.push(payload)
  },
  removeFromEmployerJobseekerShortlist(state, payload) {
    const jobseeker = state.employerJobseekerShortlist.find(
      each => each.username === payload.username
    )
    state.employerJobseekerShortlist.splice(
      state.employerJobseekerShortlist.indexOf(jobseeker),
      1
    )
  },
  resetEmployerJobSeekerShortlist(state) {
    state.employerJobseekerShortlist = []
  },
  displayAlertMessage(state, payload) {
    state.showAlertMessage = payload
  }
}

export const getters = {
  isShortlistedEmployerJobseeker: state => username => {
    return state.employerJobseekerShortlist.find(o => o.username === username)
  },
  hasShortlistedEmployerJobseeker(state) {
    return !!state.employerJobseekerShortlist.length
  },
  shortlistedEmployerJobseekerCount(state) {
    return state.employerJobseekerShortlist.length
  },
  shortlistedJobseekerUsernames(state) {
    return state.employerJobseekerShortlist.map(o => o.username)
  },
  shortlistedJobseeker(state) {
    return state.employerJobseekerShortlist
  },
  alertMessage(state) {
    return !!state.showAlertMessage
  }
}

export const actions = {
  triggerAlertMessage({ commit }, payload) {
    commit('displayAlertMessage', payload)
  },
  addToEmployerJobseekerShortlist({ commit, dispatch }, payload) {
    commit('addToEmployerJobseekerShortlist', payload)
    dispatch('syncToStore')
  },
  removeFromEmployerJobseekerShortlist({ commit, dispatch }, payload) {
    commit('removeFromEmployerJobseekerShortlist', payload)
    dispatch('syncToStore')
  },
  resetEmployerJobSeekerShortlist({ commit, dispatch }) {
    commit('resetEmployerJobSeekerShortlist')
    dispatch('syncToStore')
  },
  syncToStore({ state }) {
    if (process.client) {
      localStorage.setItem(
        `${storePrefix}-employerJobseekerShortlist`,
        JSON.stringify(state.employerJobseekerShortlist)
      )
    }
  },
  syncFromStore({ commit }, payload) {
    commit('syncFromStore', payload)
  }
}
