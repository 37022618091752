function overflowHidden(value) {
  if (value) {
    document.querySelector('html').style.overflow = 'hidden'
  } else {
    document.querySelector('html').style.overflow = 'auto'
  }
}
function truncateChars(value, truncateLimit) {
  const limit = truncateLimit || 25
  if (value && value.length < limit) return value
  return value.substring(0, limit) + '..'
}

function isEmptyObject(obj) {
  return Object.entries(obj).length === 0 && obj.constructor === Object
}
function keyValueConverter(array) {
  if (array.length) {
    return array.map(x => ({ name: x, value: x }))
  }
}
function scrollToContent(id) {
  if (id) {
    const element = document.getElementById(id)
    const position = element.getBoundingClientRect()
    const win = element.ownerDocument.defaultView
    const x = position.left + win.pageXOffset
    const y = position.top + win.pageYOffset - 60
    window.scrollTo(x, y)
  }
}

export {
  overflowHidden,
  truncateChars,
  isEmptyObject,
  keyValueConverter,
  scrollToContent
}
