<template>
  <div class="" :style="styleProp">
    <div :class="screen === 'mobile' ? '' : 'columns fill-height'">
      <div class="card column is-6 black overflow-hidden pa-0 is-hidden-touch">
        <img
          :src="
            isEmployer === true
              ? `/images/account/employer.png`
              : `/images/account/rojgaridialog.png`
          "
          class="account-image"
        />
      </div>
      <div
        class="card column has-padding-left-30-mobile has-padding-right-30-mobile has-padding-bottom-30-mobile has-padding-top-30-mobile has-padding-left-30-desktop has-padding-right-30-desktop has-padding-bottom-40-desktop has-padding-top-40-desktop overflow-auto"
      >
        <transition name="account">
          <component
            :is="currentComponent"
            :extra-info="extraInfo"
            :initial-form="initialForm"
            :employer="isEmployer"
            :send-otp-in-init.sync="sendOtpInInit"
            @close="$emit('close')"
            @initialFormSuccess="$emit('initialFormSuccess')"
            @change="changeView"
            @changeUser="switchUserType"
          ></component>
        </transition>
      </div>
    </div>
    <vue-snackbar></vue-snackbar>
  </div>
</template>

<script>
import VueSnackbar from '@/components/Commons/VueSnackbar'
import { mapGetters } from 'vuex'
export default {
  components: {
    JobseekerSignIn: () => import('@/components/Accounts/SignIn'),
    EmployerSignIn: () => import('@/components/Accounts/SignIn'),
    JobseekerSignUp: () =>
      import('@/components/Accounts/SignUp/SignUpStepFirst.vue'),
    EmployerSignUp: () =>
      import('@/components/Accounts/SignUp/SignUpStepFirst.vue'),
    ForgotPassword: () => import('@/components/Accounts/ForgotPassword'),
    VerifyCode: () => import('@/components/Accounts/VerifyCode.vue'),
    SetPassword: () => import('@/components/Accounts/SetPassword.vue'),
    JobseekerDetails: () => import('@/components/Accounts/JobseekerDetails'),
    EmployerDetails: () =>
      import(
        '@/components/Organization/Profile/BasicInformation/Forms/OrganizationForm.vue'
      ),
    EnrollDetails: () => import('@/components/Accounts/EnrollDetails'),
    VueSnackbar
  },
  props: {
    type: {
      type: String,
      default: 'sign-in'
    },
    extraInfo: {
      type: String,
      default: ''
    },
    styleProp: {
      type: String,
      default: 'width:75%;height:85vh'
    },
    initialForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isEmployer: !!(
        this.extraInfo === 'pricing' ||
        this.extraInfo === 'employer-sign-in' ||
        this.get(this.$auth, 'user.employer_profile')
      ),
      randomNumber: Math.floor(Math.random() * 4) + 1,
      sendOtpInInit: false
    }
  },
  computed: {
    ...mapGetters({ screen: 'getScreen' }),
    currentComponent() {
      if (['sign-in', 'details'].includes(this.type)) {
        return `${this.isEmployer ? 'employer' : 'jobseeker'}-${this.type}`
      } else if (this.type === 'sign-up') {
        return `employer-${this.type}`
      }
      return this.type
    }
  },
  methods: {
    switchUserType(userType) {
      this.isEmployer = userType === 'employer'
    },
    changeView(viewName) {
      if (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.referrer_profile
      ) {
        this.$emit('close')
      } else {
        this.$emit('change', { type: viewName, extraInfo: this.extraInfo })
      }
      window.history.pushState('', '', viewName)
    }
  }
}
</script>

<style scoped>
.account-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}
</style>
