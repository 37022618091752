<template>
  <div
    :class="{ 'is-background-black': dark }"
    class="columns is-vcentered is-shadowless is-marginless is-mobile"
  >
    <div class="column is-7-desktop is-9-mobile">
      <div class="columns is-vcentered is-mobile">
        <div class="column is-1-desktop is-2-mobile">
          <span class="has-margin-right-15-desktop is-size-5-mobile">
            <vue-icon-base
              height="24"
              width="24"
              :path="$options.icons[$kc(icon)]"
            >
            </vue-icon-base>
          </span>
        </div>
        <div class="column is-11-mobile p-0">
          <a
            v-if="clickable"
            class="is-size-6 has-text-weight-bold"
            @click="$emit('click')"
          >
            {{ subtitle }}
          </a>
          <div
            v-else
            :class="{ 'has-text-white': dark }"
            class="is-size-6 has-text-weight-bold"
          >
            {{ subtitle }}
          </div>
        </div>
      </div>
    </div>
    <div class="column has-text-right is-5-desktop is-2-mobile">
      <button v-if="closable" class="button" @click="$emit('close')">
        <vue-icon-base
          height="16"
          width="16"
          :path="$options.icons[$kc('uni-angle-down')]"
        >
        </vue-icon-base>
      </button>
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
import VueIconBase from '@/components/Commons/VueIconBase'

import {
  uniPresentationLine,
  uniUser,
  uniBuilding,
  uniDashboard,
  uniUserSquare,
  uniBookOpen,
  uniFileAlt,
  uniAngleDown,
  uniNotes,
  uniBell,
  uniBriefcase,
  uniDocument,
  uniLockAccess,
  uniEnvelope,
  uniInfoCircle,
  uniPhone,
  uniGlobe,
  uniChatBubbleUser,
  uniShareAlt
} from '@/helpers/icons'

export default {
  icons: {
    uniPresentationLine,
    uniUser,
    uniBuilding,
    uniDashboard,
    uniUserSquare,
    uniBookOpen,
    uniFileAlt,
    uniAngleDown,
    uniNotes,
    uniBell,
    uniBriefcase,
    uniDocument,
    uniLockAccess,
    uniEnvelope,
    uniInfoCircle,
    uniPhone,
    uniGlobe,
    uniChatBubbleUser,
    uniShareAlt
  },
  components: {
    VueIconBase
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    clickable: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    }
  }
}
</script>
