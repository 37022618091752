import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6f2ba48e = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _70c47e18 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _09fa1012 = () => interopDefault(import('../pages/business/index.vue' /* webpackChunkName: "pages/business/index" */))
const _55518d61 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _222e82ff = () => interopDefault(import('../pages/employer-zone.vue' /* webpackChunkName: "pages/employer-zone" */))
const _4ab76101 = () => interopDefault(import('../pages/employer/zone.vue' /* webpackChunkName: "pages/employer/zone" */))
const _93c861cc = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _77ac6b4a = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _a704ce6e = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _aa4e8f6e = () => interopDefault(import('../pages/project-kvs-draft.vue' /* webpackChunkName: "pages/project-kvs-draft" */))
const _f14bb3a8 = () => interopDefault(import('../pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _64ca133b = () => interopDefault(import('../pages/sep-faqs.vue' /* webpackChunkName: "pages/sep-faqs" */))
const _903b0c14 = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _670e5f28 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _dc5c55ac = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _28c82a16 = () => interopDefault(import('../pages/terms-condition.vue' /* webpackChunkName: "pages/terms-condition" */))
const _b24f7ea8 = () => interopDefault(import('../pages/training/index.vue' /* webpackChunkName: "pages/training/index" */))
const _3f59a58c = () => interopDefault(import('../pages/covid-19/survey.vue' /* webpackChunkName: "pages/covid-19/survey" */))
const _681ab3e6 = () => interopDefault(import('../pages/employer/sign-up.vue' /* webpackChunkName: "pages/employer/sign-up" */))
const _e239015a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _984c987e = () => interopDefault(import('../pages/job/post.vue' /* webpackChunkName: "pages/job/post" */))
const _7bae827a = () => interopDefault(import('../pages/job/preview/index.vue' /* webpackChunkName: "pages/job/preview/index" */))
const _70dd0b68 = () => interopDefault(import('../pages/job/search/index.vue' /* webpackChunkName: "pages/job/search/index" */))
const _4222e6c6 = () => interopDefault(import('../pages/jobseeker/data.vue' /* webpackChunkName: "pages/jobseeker/data" */))
const _966f6712 = () => interopDefault(import('../pages/jobseeker/insights.vue' /* webpackChunkName: "pages/jobseeker/insights" */))
const _2b482f18 = () => interopDefault(import('../pages/jobseeker/registration/index.vue' /* webpackChunkName: "pages/jobseeker/registration/index" */))
const _5a007f72 = () => interopDefault(import('../pages/jobseeker/search/index.vue' /* webpackChunkName: "pages/jobseeker/search/index" */))
const _c217d29a = () => interopDefault(import('../pages/jobseeker/stats.vue' /* webpackChunkName: "pages/jobseeker/stats" */))
const _9a23f9cc = () => interopDefault(import('../pages/oauth2/callback.vue' /* webpackChunkName: "pages/oauth2/callback" */))
const _219024f1 = () => interopDefault(import('../pages/payment/cart-summary.vue' /* webpackChunkName: "pages/payment/cart-summary" */))
const _2a75d242 = () => interopDefault(import('../pages/payment/failure.vue' /* webpackChunkName: "pages/payment/failure" */))
const _6360fc3b = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _dea9c18a = () => interopDefault(import('../pages/settings/change-password.vue' /* webpackChunkName: "pages/settings/change-password" */))
const _ce0c0636 = () => interopDefault(import('../pages/signup/basic-information.vue' /* webpackChunkName: "pages/signup/basic-information" */))
const _8b41cdda = () => interopDefault(import('../pages/signup/employer.vue' /* webpackChunkName: "pages/signup/employer" */))
const _7bd3fed0 = () => interopDefault(import('../pages/signup/employer-set-password.vue' /* webpackChunkName: "pages/signup/employer-set-password" */))
const _28d699fe = () => interopDefault(import('../pages/signup/employer-verify-otp.vue' /* webpackChunkName: "pages/signup/employer-verify-otp" */))
const _1586db7a = () => interopDefault(import('../pages/signup/jobseeker.vue' /* webpackChunkName: "pages/signup/jobseeker" */))
const _7ce41900 = () => interopDefault(import('../pages/signup/organization-details.vue' /* webpackChunkName: "pages/signup/organization-details" */))
const _5482d278 = () => interopDefault(import('../pages/signup/prefered-job-category.vue' /* webpackChunkName: "pages/signup/prefered-job-category" */))
const _54627e2a = () => interopDefault(import('../pages/signup/prefered-location.vue' /* webpackChunkName: "pages/signup/prefered-location" */))
const _5e294281 = () => interopDefault(import('../pages/signup/profile.vue' /* webpackChunkName: "pages/signup/profile" */))
const _5aeb747e = () => interopDefault(import('../pages/signup/set-password.vue' /* webpackChunkName: "pages/signup/set-password" */))
const _03c9bc2f = () => interopDefault(import('../pages/signup/verify-otp.vue' /* webpackChunkName: "pages/signup/verify-otp" */))
const _5902a6e6 = () => interopDefault(import('../pages/signup/working-status.vue' /* webpackChunkName: "pages/signup/working-status" */))
const _fc1fc16c = () => interopDefault(import('../pages/blog/category/_slug/index.vue' /* webpackChunkName: "pages/blog/category/_slug/index" */))
const _4a7bebc6 = () => interopDefault(import('../pages/blog/detail/_slug/index.vue' /* webpackChunkName: "pages/blog/detail/_slug/index" */))
const _284eb08c = () => interopDefault(import('../pages/blog/tag/_slug/index.vue' /* webpackChunkName: "pages/blog/tag/_slug/index" */))
const _2ea3f06c = () => interopDefault(import('../pages/business/category/_slug/index.vue' /* webpackChunkName: "pages/business/category/_slug/index" */))
const _371bbbbf = () => interopDefault(import('../pages/business/detail/_slug/index.vue' /* webpackChunkName: "pages/business/detail/_slug/index" */))
const _4d482a91 = () => interopDefault(import('../pages/employer/detail/_id.vue' /* webpackChunkName: "pages/employer/detail/_id" */))
const _4ecba58f = () => interopDefault(import('../pages/employer/profile/_id.vue' /* webpackChunkName: "pages/employer/profile/_id" */))
const _7d7b4064 = () => interopDefault(import('../pages/faq/category/_slug/index.vue' /* webpackChunkName: "pages/faq/category/_slug/index" */))
const _de12ac92 = () => interopDefault(import('../pages/faq/detail/_slug/index.vue' /* webpackChunkName: "pages/faq/detail/_slug/index" */))
const _f4a36d02 = () => interopDefault(import('../pages/faq/tags/_slug/index.vue' /* webpackChunkName: "pages/faq/tags/_slug/index" */))
const _7f6ec230 = () => interopDefault(import('../pages/jobseeker/profile/_id.vue' /* webpackChunkName: "pages/jobseeker/profile/_id" */))
const _4727f2f3 = () => interopDefault(import('../pages/referrer/profile/_id.vue' /* webpackChunkName: "pages/referrer/profile/_id" */))
const _7cd8656e = () => interopDefault(import('../pages/errors/_statusCode.vue' /* webpackChunkName: "pages/errors/_statusCode" */))
const _b5bcc138 = () => interopDefault(import('../pages/training/_slug.vue' /* webpackChunkName: "pages/training/_slug" */))
const _5a166b58 = () => interopDefault(import('../pages/job/_jobSlug/applications.vue' /* webpackChunkName: "pages/job/_jobSlug/applications" */))
const _5199f146 = () => interopDefault(import('../pages/job/_jobSlug/detail.vue' /* webpackChunkName: "pages/job/_jobSlug/detail" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _6f2ba48e,
    name: "about___ne"
  }, {
    path: "/blog",
    component: _70c47e18,
    name: "blog___ne"
  }, {
    path: "/business",
    component: _09fa1012,
    name: "business___ne"
  }, {
    path: "/contact",
    component: _55518d61,
    name: "contact___ne"
  }, {
    path: "/employer-zone",
    component: _222e82ff,
    children: [{
      path: "",
      component: _4ab76101,
      name: "employer-zone___ne"
    }]
  }, {
    path: "/faq",
    component: _93c861cc,
    name: "faq___ne"
  }, {
    path: "/pricing",
    component: _77ac6b4a,
    name: "pricing___ne"
  }, {
    path: "/privacy",
    component: _a704ce6e,
    name: "privacy___ne"
  }, {
    path: "/project-kvs-draft",
    component: _aa4e8f6e,
    name: "project-kvs-draft___ne"
  }, {
    path: "/referral",
    component: _f14bb3a8,
    name: "referral___ne"
  }, {
    path: "/sep-faqs",
    component: _64ca133b,
    name: "sep-faqs___ne"
  }, {
    path: "/sign-in",
    component: _903b0c14,
    name: "sign-in___ne"
  }, {
    path: "/sign-up",
    component: _670e5f28,
    name: "sign-up___ne"
  }, {
    path: "/signup",
    component: _dc5c55ac,
    name: "signup___ne"
  }, {
    path: "/terms-condition",
    component: _28c82a16,
    name: "terms-condition___ne"
  }, {
    path: "/training",
    component: _b24f7ea8,
    name: "training___ne"
  }, {
    path: "/covid-19/survey",
    component: _3f59a58c,
    name: "covid-19-survey___ne"
  }, {
    path: "/employer/sign-up",
    component: _681ab3e6,
    name: "employer-sign-up___ne"
  }, {
    path: "/en/",
    component: _e239015a,
    name: "index___en"
  }, {
    path: "/en/about",
    component: _6f2ba48e,
    name: "about___en"
  }, {
    path: "/en/blog",
    component: _70c47e18,
    name: "blog___en"
  }, {
    path: "/en/business",
    component: _09fa1012,
    name: "business___en"
  }, {
    path: "/en/contact",
    component: _55518d61,
    name: "contact___en"
  }, {
    path: "/en/employer-zone",
    component: _222e82ff,
    children: [{
      path: "",
      component: _4ab76101,
      name: "employer-zone___en"
    }]
  }, {
    path: "/en/faq",
    component: _93c861cc,
    name: "faq___en"
  }, {
    path: "/en/pricing",
    component: _77ac6b4a,
    name: "pricing___en"
  }, {
    path: "/en/privacy",
    component: _a704ce6e,
    name: "privacy___en"
  }, {
    path: "/en/project-kvs-draft",
    component: _aa4e8f6e,
    name: "project-kvs-draft___en"
  }, {
    path: "/en/referral",
    component: _f14bb3a8,
    name: "referral___en"
  }, {
    path: "/en/sep-faqs",
    component: _64ca133b,
    name: "sep-faqs___en"
  }, {
    path: "/en/sign-in",
    component: _903b0c14,
    name: "sign-in___en"
  }, {
    path: "/en/sign-up",
    component: _670e5f28,
    name: "sign-up___en"
  }, {
    path: "/en/signup",
    component: _dc5c55ac,
    name: "signup___en"
  }, {
    path: "/en/terms-condition",
    component: _28c82a16,
    name: "terms-condition___en"
  }, {
    path: "/en/training",
    component: _b24f7ea8,
    name: "training___en"
  }, {
    path: "/job/post",
    component: _984c987e,
    name: "job-post___ne"
  }, {
    path: "/job/preview",
    component: _7bae827a,
    name: "job-preview___ne"
  }, {
    path: "/job/search",
    component: _70dd0b68,
    name: "job-search___ne"
  }, {
    path: "/jobseeker/data",
    component: _4222e6c6,
    name: "jobseeker-data___ne"
  }, {
    path: "/jobseeker/insights",
    component: _966f6712,
    name: "jobseeker-insights___ne"
  }, {
    path: "/jobseeker/registration",
    component: _2b482f18,
    name: "jobseeker-registration___ne"
  }, {
    path: "/jobseeker/search",
    component: _5a007f72,
    name: "jobseeker-search___ne"
  }, {
    path: "/jobseeker/stats",
    component: _c217d29a,
    name: "jobseeker-stats___ne"
  }, {
    path: "/oauth2/callback",
    component: _9a23f9cc,
    name: "oauth2-callback___ne"
  }, {
    path: "/payment/cart-summary",
    component: _219024f1,
    name: "payment-cart-summary___ne"
  }, {
    path: "/payment/failure",
    component: _2a75d242,
    name: "payment-failure___ne"
  }, {
    path: "/payment/success",
    component: _6360fc3b,
    name: "payment-success___ne"
  }, {
    path: "/settings/change-password",
    component: _dea9c18a,
    name: "settings-change-password___ne"
  }, {
    path: "/signup/basic-information",
    component: _ce0c0636,
    name: "signup-basic-information___ne"
  }, {
    path: "/signup/employer",
    component: _8b41cdda,
    name: "signup-employer___ne"
  }, {
    path: "/signup/employer-set-password",
    component: _7bd3fed0,
    name: "signup-employer-set-password___ne"
  }, {
    path: "/signup/employer-verify-otp",
    component: _28d699fe,
    name: "signup-employer-verify-otp___ne"
  }, {
    path: "/signup/jobseeker",
    component: _1586db7a,
    name: "signup-jobseeker___ne"
  }, {
    path: "/signup/organization-details",
    component: _7ce41900,
    name: "signup-organization-details___ne"
  }, {
    path: "/signup/prefered-job-category",
    component: _5482d278,
    name: "signup-prefered-job-category___ne"
  }, {
    path: "/signup/prefered-location",
    component: _54627e2a,
    name: "signup-prefered-location___ne"
  }, {
    path: "/signup/profile",
    component: _5e294281,
    name: "signup-profile___ne"
  }, {
    path: "/signup/set-password",
    component: _5aeb747e,
    name: "signup-set-password___ne"
  }, {
    path: "/signup/verify-otp",
    component: _03c9bc2f,
    name: "signup-verify-otp___ne"
  }, {
    path: "/signup/working-status",
    component: _5902a6e6,
    name: "signup-working-status___ne"
  }, {
    path: "/en/covid-19/survey",
    component: _3f59a58c,
    name: "covid-19-survey___en"
  }, {
    path: "/en/employer/sign-up",
    component: _681ab3e6,
    name: "employer-sign-up___en"
  }, {
    path: "/en/job/post",
    component: _984c987e,
    name: "job-post___en"
  }, {
    path: "/en/job/preview",
    component: _7bae827a,
    name: "job-preview___en"
  }, {
    path: "/en/job/search",
    component: _70dd0b68,
    name: "job-search___en"
  }, {
    path: "/en/jobseeker/data",
    component: _4222e6c6,
    name: "jobseeker-data___en"
  }, {
    path: "/en/jobseeker/insights",
    component: _966f6712,
    name: "jobseeker-insights___en"
  }, {
    path: "/en/jobseeker/registration",
    component: _2b482f18,
    name: "jobseeker-registration___en"
  }, {
    path: "/en/jobseeker/search",
    component: _5a007f72,
    name: "jobseeker-search___en"
  }, {
    path: "/en/jobseeker/stats",
    component: _c217d29a,
    name: "jobseeker-stats___en"
  }, {
    path: "/en/oauth2/callback",
    component: _9a23f9cc,
    name: "oauth2-callback___en"
  }, {
    path: "/en/payment/cart-summary",
    component: _219024f1,
    name: "payment-cart-summary___en"
  }, {
    path: "/en/payment/failure",
    component: _2a75d242,
    name: "payment-failure___en"
  }, {
    path: "/en/payment/success",
    component: _6360fc3b,
    name: "payment-success___en"
  }, {
    path: "/en/settings/change-password",
    component: _dea9c18a,
    name: "settings-change-password___en"
  }, {
    path: "/en/signup/basic-information",
    component: _ce0c0636,
    name: "signup-basic-information___en"
  }, {
    path: "/en/signup/employer",
    component: _8b41cdda,
    name: "signup-employer___en"
  }, {
    path: "/en/signup/employer-set-password",
    component: _7bd3fed0,
    name: "signup-employer-set-password___en"
  }, {
    path: "/en/signup/employer-verify-otp",
    component: _28d699fe,
    name: "signup-employer-verify-otp___en"
  }, {
    path: "/en/signup/jobseeker",
    component: _1586db7a,
    name: "signup-jobseeker___en"
  }, {
    path: "/en/signup/organization-details",
    component: _7ce41900,
    name: "signup-organization-details___en"
  }, {
    path: "/en/signup/prefered-job-category",
    component: _5482d278,
    name: "signup-prefered-job-category___en"
  }, {
    path: "/en/signup/prefered-location",
    component: _54627e2a,
    name: "signup-prefered-location___en"
  }, {
    path: "/en/signup/profile",
    component: _5e294281,
    name: "signup-profile___en"
  }, {
    path: "/en/signup/set-password",
    component: _5aeb747e,
    name: "signup-set-password___en"
  }, {
    path: "/en/signup/verify-otp",
    component: _03c9bc2f,
    name: "signup-verify-otp___en"
  }, {
    path: "/en/signup/working-status",
    component: _5902a6e6,
    name: "signup-working-status___en"
  }, {
    path: "/en/blog/category/:slug?",
    component: _fc1fc16c,
    name: "blog-category-slug___en"
  }, {
    path: "/en/blog/detail/:slug?",
    component: _4a7bebc6,
    name: "blog-detail-slug___en"
  }, {
    path: "/en/blog/tag/:slug?",
    component: _284eb08c,
    name: "blog-tag-slug___en"
  }, {
    path: "/en/business/category/:slug?",
    component: _2ea3f06c,
    name: "business-category-slug___en"
  }, {
    path: "/en/business/detail/:slug?",
    component: _371bbbbf,
    name: "business-detail-slug___en"
  }, {
    path: "/en/employer/detail/:id?",
    component: _4d482a91,
    name: "employer-detail-id___en"
  }, {
    path: "/en/employer/profile/:id?",
    component: _4ecba58f,
    name: "employer-profile-id___en"
  }, {
    path: "/en/faq/category/:slug?",
    component: _7d7b4064,
    name: "faq-category-slug___en"
  }, {
    path: "/en/faq/detail/:slug?",
    component: _de12ac92,
    name: "faq-detail-slug___en"
  }, {
    path: "/en/faq/tags/:slug?",
    component: _f4a36d02,
    name: "faq-tags-slug___en"
  }, {
    path: "/en/jobseeker/profile/:id?",
    component: _7f6ec230,
    name: "jobseeker-profile-id___en"
  }, {
    path: "/en/referrer/profile/:id?",
    component: _4727f2f3,
    name: "referrer-profile-id___en"
  }, {
    path: "/blog/category/:slug?",
    component: _fc1fc16c,
    name: "blog-category-slug___ne"
  }, {
    path: "/blog/detail/:slug?",
    component: _4a7bebc6,
    name: "blog-detail-slug___ne"
  }, {
    path: "/blog/tag/:slug?",
    component: _284eb08c,
    name: "blog-tag-slug___ne"
  }, {
    path: "/business/category/:slug?",
    component: _2ea3f06c,
    name: "business-category-slug___ne"
  }, {
    path: "/business/detail/:slug?",
    component: _371bbbbf,
    name: "business-detail-slug___ne"
  }, {
    path: "/employer/detail/:id?",
    component: _4d482a91,
    name: "employer-detail-id___ne"
  }, {
    path: "/employer/profile/:id?",
    component: _4ecba58f,
    name: "employer-profile-id___ne"
  }, {
    path: "/en/errors/:statusCode?",
    component: _7cd8656e,
    name: "errors-statusCode___en"
  }, {
    path: "/en/training/:slug",
    component: _b5bcc138,
    name: "training-slug___en"
  }, {
    path: "/faq/category/:slug?",
    component: _7d7b4064,
    name: "faq-category-slug___ne"
  }, {
    path: "/faq/detail/:slug?",
    component: _de12ac92,
    name: "faq-detail-slug___ne"
  }, {
    path: "/faq/tags/:slug?",
    component: _f4a36d02,
    name: "faq-tags-slug___ne"
  }, {
    path: "/jobseeker/profile/:id?",
    component: _7f6ec230,
    name: "jobseeker-profile-id___ne"
  }, {
    path: "/referrer/profile/:id?",
    component: _4727f2f3,
    name: "referrer-profile-id___ne"
  }, {
    path: "/en/job/:jobSlug?/applications",
    component: _5a166b58,
    name: "job-jobSlug-applications___en"
  }, {
    path: "/en/job/:jobSlug?/detail",
    component: _5199f146,
    name: "job-jobSlug-detail___en"
  }, {
    path: "/errors/:statusCode?",
    component: _7cd8656e,
    name: "errors-statusCode___ne"
  }, {
    path: "/training/:slug",
    component: _b5bcc138,
    name: "training-slug___ne"
  }, {
    path: "/job/:jobSlug?/applications",
    component: _5a166b58,
    name: "job-jobSlug-applications___ne"
  }, {
    path: "/job/:jobSlug?/detail",
    component: _5199f146,
    name: "job-jobSlug-detail___ne"
  }, {
    path: "/",
    component: _e239015a,
    name: "index___ne"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
