<template>
  <validation-observer ref="observer">
    <div
      id="topOfForm"
      class="card-content has-padding-left-100-desktop has-padding-right-100-desktop"
    >
      <div class="columns is-multiline">
        <div v-if="nonFieldErrors.length" class="column is-12">
          <vue-form-errors :non-field-errors="nonFieldErrors"></vue-form-errors>
        </div>
        <div class="column is-12 is-size-4 has-text-weight-bold">
          {{ $t('Please fill your basic information') }}
        </div>
        <div class="column is-12 py-0"><hr class="my-1" /></div>
        <div class="column is-12">
          <div class="columns is-multiline is-vcentered is-desktop is-mobile">
            <div class="column is-10-desktop is-8-mobile has-text-weight-bold">
              {{ $t('Are there any benefits offered for this job') }}?
            </div>
            <div class="column is-2-desktop is-4-mobile has-text-right">
              <vue-switch v-model="has_benefits"></vue-switch>
            </div>
            <div v-if="has_benefits" class="column is-12 py-0">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Benefits')"
                rules="max_length_10"
              >
                <label class="label is-size-8 has-text-grey">
                  <vue-icon-base
                    height="15"
                    width="15"
                    :path="$options.icons[$kc('uni-smile-beam')]"
                  >
                  </vue-icon-base>
                  {{ $t('Benefits') }}
                </label>
                <vue-auto-complete
                  v-model="formValues.benefits"
                  add-item-title="Benefit"
                  item-text="name"
                  add-item-button
                  item-value="id"
                  endpoint="/api/v1/common/job-benefit/"
                  allow-multiple
                  allow-create
                  @updateSelectedItems="formValues.selectedBenefits = $event"
                ></vue-auto-complete>
                <p class="field-error help is-danger">
                  {{ errors[0] || fieldErrors.benefits }}
                </p>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="column is-12 py-0"><hr class="my-1" /></div>
        <div class="column is-12">
          <div class="columns is-multiline is-vcentered is-desktop is-mobile">
            <div class="column is-10-desktop is-8-mobile has-text-weight-bold">
              {{ $t('Does a candidate require two wheeler') }}?
            </div>
            <div class="column is-2-desktop is-4-mobile has-text-right">
              <vue-switch
                v-model="formValues.required_two_wheeler"
              ></vue-switch>
            </div>
          </div>
        </div>
        <div class="column is-12 py-0"><hr class="my-1" /></div>
        <div class="column is-12">
          <div class="columns is-multiline is-vcentered is-desktop is-mobile">
            <div class="column is-10-desktop is-8-mobile has-text-weight-bold">
              {{ $t('Does s/he need to submit supporting documents') }}?
            </div>
            <div class="column is-2-desktop is-4-mobile has-text-right">
              <vue-switch
                v-model="formValues.is_document_required"
              ></vue-switch>
            </div>

            <div
              v-if="formValues.is_document_required"
              class="column is-12 py-0"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Supporting Documents')"
                rules="max_length_10"
              >
                <label class="label is-size-8 has-text-grey">
                  <vue-icon-base
                    height="15"
                    width="15"
                    :path="$options.icons[$kc('uni-file-alt')]"
                  >
                  </vue-icon-base>
                  {{ $t('Supporting Documents') }}
                </label>
                <vue-auto-complete
                  v-model="formValues.document_categories"
                  item-text="name"
                  item-value="id"
                  endpoint="/api/v1/common/document-categories/"
                  :extra-filter="{ limit: 20 }"
                  allow-multiple
                  @updateSelectedItems="formValues.selectedDocuments = $event"
                ></vue-auto-complete>
                <p class="field-error help is-danger">
                  {{ errors[0] || fieldErrors.document_categories }}
                </p>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="column is-12 py-0"><hr class="my-1" /></div>
        <div class="column is-12">
          <div class="columns is-multiline is-vcentered is-desktop is-mobile">
            <div class="column is-10-desktop is-8-mobile has-text-weight-bold">
              {{ $t('Is there any gender preference for this job') }}?
            </div>
            <div class="column is-2-desktop is-4-mobile has-text-right">
              <vue-switch v-model="formValues.is_gender_specific"></vue-switch>
            </div>
            <div v-if="formValues.is_gender_specific" class="column is-6 py-0">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Preferred Gender')"
                rules="required"
              >
                <div class="field">
                  <label class="label is-size-8 has-text-grey">
                    <vue-icon-base
                      height="15"
                      width="15"
                      :path="$options.icons[$kc('uni-mars')]"
                    >
                    </vue-icon-base>
                    {{ $t('Preferred Gender') }}
                    <span class="has-text-danger">*</span>
                  </label>
                  <div class="control">
                    <div class="select is-full-width pt-1">
                      <select
                        v-model="formValues.gender"
                        class="is-full-width is-size-6"
                      >
                        <option
                          v-for="(gender, index) in genderChoices"
                          :key="index"
                          :value="gender.value"
                        >
                          {{ $t(gender.name) }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <p class="field-error help is-danger">
                    {{ errors[0] || fieldErrors.gender }}
                  </p>
                </div>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="column is-12 py-0"><hr class="my-1" /></div>
        <div class="column is-12">
          <div class="columns is-multiline is-vcentered is-desktop is-mobile">
            <div class="column is-10-desktop is-8-mobile has-text-weight-bold">
              {{ $t('Is there any specific age criteria for this job') }}?
            </div>
            <div class="column is-2-desktop is-4-mobile has-text-right">
              <vue-switch v-model="formValues.is_age_specific"></vue-switch>
            </div>
            <div
              v-if="formValues.is_age_specific"
              class="column is-6-desktop is-12-mobile py-0"
            >
              <div style="display: flex" class="control">
                <div class="select mr-5">
                  <select v-model="formValues.age_unit">
                    <!-- <option value="">{{ $t('Select Unit') }}</option> -->
                    <option value="more">{{ $t('Above') }}</option>
                    <option value="less">{{ $t('Below') }}</option>
                  </select>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('Age')"
                  rules="required|numeric|min_value:16|max_value:100"
                >
                  <p class="control mr-3">
                    <input
                      v-model="formValues.age"
                      class="input"
                      min="16"
                      max="100"
                      type="number"
                      :placeholder="$t('Select Age')"
                    />
                  </p>
                  <p class="field-error help is-danger">
                    {{ errors[0] || fieldErrors.age }}
                  </p>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12 py-0"><hr class="my-1" /></div>
        <div class="column is-12">
          <div class="columns is-multiline is-vcentered is-desktop is-mobile">
            <div class="column is-10-desktop is-8-mobile has-text-weight-bold">
              {{ $t('Do you want to hide Organization Name') }}?
            </div>
            <div class="column is-2-desktop is-4-mobile has-text-right">
              <vue-switch v-model="hide_org_name"></vue-switch>
            </div>
            <div
              v-if="hide_org_name"
              class="column is-6-desktop is-12-mobile py-0"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Alternative Name')"
                rules="required"
              >
                <label class="label is-size-8 has-text-grey">
                  <vue-icon-base
                    height="15"
                    width="15"
                    :path="$options.icons[$kc('uni-building')]"
                  >
                  </vue-icon-base>
                  {{ $t('Alternative Name') }}
                </label>
                <input
                  v-model="formValues.org_alt_name"
                  :class="{
                    'is-danger': errors.length || fieldErrors.org_alt_name
                  }"
                  class="input"
                  type="text"
                  :placeholder="$t('Alternative Name')"
                />
                <p class="field-error help is-danger">
                  {{ errors[0] || fieldErrors.org_alt_name }}
                </p>
              </validation-provider>
            </div>
          </div>
        </div>
        <!-- <div class="column is-12 py-0"><hr class="my-1" /></div> -->
        <!-- <div class="column is-12">
          <div class="columns is-multiline is-vcentered is-desktop is-mobile">
            <div class="column is-10-desktop is-8-mobile has-text-weight-bold">
              {{ $t('Do you want to ask questions for applicant') }}?
            </div>
            <div class="column is-2-desktop is-4-mobile has-text-right">
              <vue-switch v-model="displayQuestionSection"></vue-switch>
            </div>

            <div v-if="displayQuestionSection" class="column is-12 pt-0">
              <div v-if="!showQuestionForm">
                <div class="columns text-14 pt-4">
                  <div
                    class="column is-6 outline rounded-10 mr-2 very-light-grey"
                  >
                    <label class="is-underlined has-text-weight-bold">{{
                      $t('Preset Questions')
                    }}</label>
                    <div
                      v-for="(question, index) in presetQuestions"
                      :key="index"
                      class="mb-4 mt-2"
                    >
                      <div class="is-flex gap-05rem">
                        <span class="has-text-weight-bold"
                          >{{ index + 1 }}. {{ question.question }}</span
                        >
                        <div>
                          <button
                            v-if="!isQuestionAdded(question)"
                            class="bg-brown-employer has-text-white px-3 pointer outline logo-radius-5"
                            :disabled="loading"
                            @click="savePresetQuestion(question)"
                          >
                            {{ $t('Add') }}
                          </button>
                          <button
                            v-else
                            class="has-background-grey-lighter has-text-white logo-radius-5 px-3 outline has-text-grey"
                          >
                            {{ $t('Added') }}
                          </button>
                        </div>
                      </div>
                      <span
                        v-for="(answerChoice, index) in question.answer_choices"
                        :key="index"
                      >
                        <li class="pl-3">
                          {{ answerChoice.choice_text }}
                        </li></span
                      >
                    </div>
                  </div>
                  <div
                    v-if="addedQuestions.length"
                    class="column is-6 outline rounded-10 has-background-light-green"
                  >
                    <label class="is-underlined has-text-weight-bold">{{
                      $t('Added Questions')
                    }}</label>
                    <div
                      v-for="(addedQuestion, index) in addedQuestions"
                      :key="index"
                      class="my-1 py-2"
                    >
                      <span class="has-text-weight-bold">
                        {{ index + 1 }}. {{ addedQuestion.question }}
                      </span>
                      <button
                        :disabled="loading"
                        class="has-text-red outlineless has-background-light-green pointer"
                        @click="deleteQuestion(addedQuestion)"
                      >
                        <vue-icon-base
                          height="16"
                          width="16"
                          :path="$options.icons[$kc('uni-trash-alt')]"
                        >
                        </vue-icon-base>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <transition name="slide-fade">
                <validation-observer
                  v-if="showQuestionForm"
                  ref="question-observer"
                  tag="div"
                  class="mx-3 mb-4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Answer Type')"
                    rules="required"
                  >
                    <label class="label is-size-8 has-text-grey">
                      <vue-icon-base
                        height="15"
                        width="15"
                        :path="$options.icons[$kc('uni-file-check-alt')]"
                      >
                      </vue-icon-base>
                      {{ $t('Answer Type') }}
                    </label>
                    <div class="select is-multiple">
                      <select v-model="question.question_type">
                        <option
                          v-for="type in questionTypes"
                          :key="type.value"
                          :value="type.value"
                        >
                          {{ $t(type.text) }}
                        </option>
                      </select>
                    </div>
                    <p class="field-error help is-danger">
                      {{ errors[0] || fieldErrors.question_type }}
                    </p>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Question')"
                    rules="required"
                  >
                    <div class="field">
                      <label class="label is-size-8 has-text-grey">
                        <vue-icon-base
                          height="15"
                          width="15"
                          :path="$options.icons[$kc('uni-file-question-alt')]"
                        >
                        </vue-icon-base>
                        {{ $t('Question') }}
                      </label>
                      <div class="control">
                        <input
                          v-model="question.question"
                          :class="{
                            'is-danger': errors.length || fieldErrors.title
                          }"
                          class="input"
                          type="text"
                          :placeholder="$t('Enter Question')"
                        />
                      </div>
                      <p class="field-error help is-danger">
                        {{ errors[0] || fieldErrors.title }}
                      </p>
                    </div>
                  </validation-provider>
                  <div
                    v-if="
                      question.question_type === 'radio' ||
                      question.question_type === 'dropdown'
                    "
                  >
                    <div
                      v-for="(choice, index) in question.answer_choices"
                      :key="index"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="`${$t('Option')} - ${index + 1}`"
                        rules="required"
                      >
                        <div class="is-flex gap-1 align-center">
                          <input
                            v-model="choice.choice_text"
                            class="input mb-2"
                            :class="{
                              'is-danger':
                                errors.length || fieldErrors.answer_choices
                            }"
                            type="text"
                            :placeholder="$t('Enter Your Option')"
                          />
                          <span
                            v-if="
                              Object.keys(question.answer_choices).length > 2
                            "
                            @click="DeleteChoice(index)"
                          >
                            <vue-icon-base
                              class="has-text-danger pointer"
                              height="25"
                              width="25"
                              :path="$options.icons[$kc('uni-times')]"
                            >
                            </vue-icon-base>
                          </span>
                        </div>
                        <span class="field-error help is-danger">
                          {{ errors[0] || fieldErrors.answer_choices }}
                        </span>
                      </validation-provider>
                    </div>
                    <button
                      class="bg-brown has-text-white pa-2 mb-4"
                      @click="AddChoice()"
                    >
                      {{ $t('Add') }}
                    </button>
                  </div>
                  <label class="checkbox">
                    <input v-model="question.is_required" type="checkbox" />
                    <span class="ml-2 is-size-6">
                      {{ $t('Is Mandatory') }}
                    </span>
                  </label>
                  <div class="is-flex gap-05rem my-4">
                    <button
                      class="button is-white"
                      @click="showQuestionForm = false"
                    >
                      {{ $t('Go Back') }}
                    </button>
                    <button
                      v-if="questionToEdit === null"
                      class="button brown-outline has-text-employer"
                      @click="saveAndAddQuestion"
                    >
                      {{ $t('Save and Add Another') }}
                    </button>
                    <button class="button is-employer" @click="saveQuestion">
                      {{ $t('Save') }}
                    </button>
                  </div>
                </validation-observer>
                <div v-else class="mx-3">
                  <progress
                    v-if="loadingQuestions"
                    class="progress is-small is-neutral"
                    max="100"
                    v-text="'50%'"
                  ></progress>
                </div>
              </transition>
            </div>
          </div>
        </div> -->
        <div class="column is-12 py-0"><hr class="my-1" /></div>
      </div>
    </div>
    <div
      v-if="footer"
      class="has-padding-left-20-mobile has-padding-right-20-mobile has-padding-left-100-desktop has-padding-right-100-desktop"
    >
      <div class="columns is-multiline is-mobile">
        <div class="column has-text-right is-12 m-d-grid gap-1">
          <button
            class="button is-outlined is-employer"
            @click="gotoPreviousStep()"
            v-text="$t('Previous Step')"
          ></button>
          <button
            class="button is-employer"
            :disabled="disabled"
            @click="submitAdditionalInfo()"
            v-text="$t('Preview and Publish')"
          ></button>
        </div>
      </div>
    </div>
  </validation-observer>
</template>
<script>
import VueSwitch from '@/components/Commons/VueSwitch'
import VueAutoComplete from '@/components/Commons/VueAutoComplete'
import { extend } from 'vee-validate'
import VueIconBase from '@/components/Commons/VueIconBase'
import {
  uniTrashAlt,
  uniPlus,
  uniEye,
  uniSmileBeam,
  uniMars,
  uniTimes,
  uniBusAlt,
  uniFileAlt,
  uniBuilding,
  uniPostcard,
  uniTagAlt,
  uniEditAlt,
  uniFileCheckAlt,
  uniFileQuestionAlt
} from '@/helpers/icons'
import FormMixin from '@/mixins/veeValidate'
import { genderChoices } from '@/helpers/constants/jobseeker'
export default {
  icons: {
    uniTrashAlt,
    uniPlus,
    uniSmileBeam,
    uniEye,
    uniMars,
    uniTimes,
    uniBusAlt,
    uniFileAlt,
    uniBuilding,
    uniPostcard,
    uniTagAlt,
    uniEditAlt,
    uniFileCheckAlt,
    uniFileQuestionAlt
  },
  components: { VueSwitch, VueIconBase, VueAutoComplete },
  mixins: [FormMixin],
  props: {
    footer: {
      type: Boolean,
      default: true
    },
    additionalInfoForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      slug: '',
      loading: false,
      presetQuestions: [],
      disabled: false,
      has_benefits: false,
      hide_org_name: false,
      genderChoices,
      displayQuestionSection: false,
      showQuestionForm: false,
      questionTypes: [
        { value: 'radio', text: this.$t('Options-Select') },
        { value: 'dropdown', text: this.$t('Options-Dropdown') },
        { value: 'text', text: this.$t('Short Answer') },
        { value: 'textarea', text: this.$t('Multi-line Answer') }
      ],
      question: {
        answer_choices: [{ choice_text: '' }, { choice_text: '' }],
        question_type: 'radio',
        question: '',
        is_required: false,
        description: 'a'
      },
      questionToEdit: null,
      addedQuestions: [],
      loadingQuestions: false,
      formValues: {
        benefits: [],
        org_alt_name: '',
        selectedBenefits: [],
        selectedDocuments: [],
        is_document_required: false,
        is_gender_specific: false,
        required_two_wheeler: false,
        document_categories: [],
        gender: 'Female',
        is_age_specific: false,
        age_unit: 'more',
        min_age: null,
        max_age: null
      }
    }
  },
  watch: {
    slug() {
      this.getAddedQuestions()
      this.fetchData()
      this.getPresetQuestions()
    },
    additionalInfoForm() {
      this.submitAdditionalInfo()
    },
    '$route.query.step': {
      handler() {
        this.disabled = false
      }
    },
    has_benefits(val) {
      if (!val) {
        this.formValues.benefits = []
        this.formValues.selectedBenefits = []
      }
    },
    hide_org_name: {
      handler(val) {
        if (!val) {
          this.formValues.org_alt_name = ''
        }
      }
    },
    'formValues.is_document_required': {
      handler(val) {
        if (!val) {
          this.formValues.document_categories = []
          this.formValues.selectedDocuments = []
        }
      },
      immediate: true,
      deep: true
    },
    'formValues.is_age_specific'(val) {
      if (!val) {
        this.formValues.age_unit = 'more'
        this.formValues.age = null
      }
    }
  },
  created() {
    this.addMaxLengthvalidator()
  },
  methods: {
    isQuestionAdded(question) {
      return this.addedQuestions.some(
        addedQuestion => addedQuestion.question === question.question
      )
    },
    savePresetQuestion(question) {
      this.loading = true
      const endpoint = `/api/v1/job/${this.slug}/questions/`
      return this.$axios
        .$post(endpoint, question)
        .then(response => {
          this.addedQuestions.push(response)
          this.notifyUser(this.$t('Successfully added question'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPresetQuestions() {
      this.$axios
        .$get(`/api/v1/job/${this.slug}/questions/get-preset-questions/`)
        .then(response => {
          this.presetQuestions = response
        })
    },
    AddChoice() {
      this.question.answer_choices.push({ choice_text: '' })
    },
    DeleteChoice(index) {
      this.question.answer_choices.splice(index, 1)
    },
    async saveQuestion() {
      if (await this.$refs['question-observer'].validate()) {
        if (this.questionToEdit !== null) {
          this.updateQuestion()
        } else {
          this.submitQuestions()
        }
        this.showQuestionForm = false
      }
    },
    gotoPreviousStep() {
      this.$emit('additionalInfo', {
        formValues: this.formValues
      })
      this.$router.push(
        this.localePath({
          name: 'job-post',
          query: {
            slug: this.$route.query.slug,
            step: '2'
          }
        })
      )
      this.$emit('scrollToTop')
      this.$emit('previousStep')
    },
    async saveAndAddQuestion() {
      if (await this.$refs['question-observer'].validate()) {
        this.submitQuestions()
        this.$refs['question-observer'].reset()
      }
    },
    fetchData() {
      this.$axios.$get(`/api/v1/job/${this.slug}/`).then(response => {
        this.populateFormValues(response)
      })
    },
    constructFormValues() {
      if (!this.formValues.is_gender_specific) {
        this.formValues.gender = null
      }
      return {
        benefits: this.formValues.benefits,
        required_two_wheeler: this.formValues.required_two_wheeler,
        document_categories: this.formValues.document_categories,
        show_name: !this.hide_org_name,
        org_alt_name: this.formValues.org_alt_name,
        is_document_required: this.formValues.is_document_required,
        is_gender_specific: this.formValues.is_gender_specific,
        gender: this.formValues.gender,
        is_age_specific: this.formValues.is_age_specific,
        max_age:
          this.formValues.age_unit === 'less' ? this.formValues.age : null,
        min_age:
          this.formValues.age_unit === 'more' ? this.formValues.age : null
      }
    },
    addMaxLengthvalidator() {
      this.slug = document.URL.split('=')[1].split('&')[0]
      extend('max_length_10', {
        validate: value => {
          let validation = true
          validation = validation && value.length < 11
          return validation
        },
        message: `{_field_} ${this.$t('field must be less than or equal 10')}`
      })
    },
    async submitAdditionalInfo() {
      if (await this.validateAllFields()) {
        this.disabled = true
        const endpoint = `/api/v1/job/${this.slug}/additional-info-new/`
        this.$axios
          .$put(endpoint, this.constructFormValues())
          .then(response => {
            this.notifyUser(
              this.$t('Successfully updated Additional Information')
            )
            this.$router.push(
              this.localePath({
                name: 'job-preview',
                query: {
                  slug: this.slug
                }
              })
            )
            this.$emit('scrollToTop')
            this.$emit('additionalInfo')
          })
          .catch(error => {
            this.getError(error)
            if (this.nonFieldErrors.length) {
              document
                .getElementById('topOfForm')
                .scrollIntoView({ block: 'start', behavior: 'smooth' })
            }
          })
      }
    },
    populateFormValues(response) {
      if (response.benefits.length) {
        this.has_benefits = true
        this.formValues.benefits = response.benefits
      }
      this.formValues.document_categories = response.document_categories
      if (this.formValues.document_categories.length) {
        this.formValues.is_document_required = true
      }
      this.formValues.required_two_wheeler =
        response.setting.required_two_wheeler
      this.prepareAgeData(response)
      this.formValues.gender = response.setting.gender || 'Female'
      this.formValues.is_gender_specific = response.setting.is_gender_specific
      if (response.org_alt_name) {
        this.hide_org_name = true
      }
      this.formValues.org_alt_name = response.org_alt_name
    },
    prepareAgeData(response) {
      this.formValues.is_age_specific = response.setting.is_age_specific
      this.$set(
        this.formValues,
        'age_unit',
        response.setting.min_age ? 'more' : 'less'
      )
      this.formValues.age = response.setting.min_age || response.setting.max_age
    },
    getAddedQuestions() {
      const endpoint = `/api/v1/job/${this.slug}/questions/`
      this.loadingQuestions = true
      this.$axios
        .$get(endpoint)
        .then(response => {
          this.addedQuestions = response.results
          this.displayQuestionSection = !!response.results.length
        })
        .finally(() => {
          this.loadingQuestions = false
        })
    },
    submitQuestions() {
      const endpoint = `/api/v1/job/${this.slug}/questions/`
      if (
        this.question.question_type === 'text' ||
        this.question.question_type === 'textarea'
      ) {
        this.question.answer_choices = []
      }
      return this.$axios.$post(endpoint, this.question).then(response => {
        this.addedQuestions.push(response)
        this.notifyUser(this.$t('Successfully added question'))
        this.question = {
          answer_choices: [{ choice_text: '' }, { choice_text: '' }],
          question_type: 'radio',
          question: '',
          is_required: false,
          description: 'a'
        }
      })
    },
    updateQuestion() {
      const endpoint = `/api/v1/job/${this.slug}/questions/${this.questionToEdit.id}/`
      this.$axios.$put(endpoint, this.question).then(response => {
        const questionId = this.addedQuestions.findIndex(
          question => question.id === response.id
        )
        this.$set(this.addedQuestions, questionId, response)
        this.questionToEdit = null
        this.notifyUser(this.$t('Successfully updated question'))
        this.question = {
          answer_choices: [{ choice_text: '' }, { choice_text: '' }],
          question_type: 'radio',
          question: '',
          is_required: false,
          description: 'a'
        }
      })
    },
    showUpdateForm(question) {
      this.questionToEdit = question
      this.question = this.deepCopy(question)
      this.showQuestionForm = true
    },
    deleteQuestion(question) {
      this.loading = true
      const endpoint = `/api/v1/job/${this.slug}/questions/${question.id}/`
      this.$axios.$delete(endpoint).then(response => {
        const questionId = this.addedQuestions.findIndex(
          questionFromLoop => question.id === questionFromLoop.id
        )
        this.addedQuestions.splice(questionId, 1)
        this.notifyUser(this.$t('Successfully deleted question'))
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.is-size-8 {
  font-size: 0.85rem;
}
</style>
