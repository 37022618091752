export const vueI18n = {}
export const vueI18nLoader = false
export const locales = [{"code":"en","iso":"en-US","name":"English","file":"en/index.js"},{"code":"ne","iso":"ne-NP","name":"Nepali","file":"ne/index.js"}]
export const defaultLocale = 'ne'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_except_default'
export const lazy = true
export const langDir = '/locales/'
export const rootRedirect = null
export const detectBrowserLanguage = false
export const differentDomains = false
export const seo = true
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const encodePaths = true
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["en","ne"]
