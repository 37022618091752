export default {
  props: {
    initialItems: {
      type: [Array, Object],
      default: null
    },
    value: {
      type: [Number, Array, Object, String],
      default: undefined
    },
    allowMultiple: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    endpoint: {
      type: String,
      default: ''
    },
    itemText: {
      type: String,
      default: ''
    },
    itemValue: {
      type: String,
      default: ''
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    extraPostData: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    extraFilter: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    extraInputClass: {
      type: String,
      required: false,
      default: ''
    },
    showSelectedItems: {
      type: Boolean,
      default: true
    },
    filterBy: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      items: this.initialItems,
      formValue: this.value
        ? this.deepCopy(this.value)
        : this.allowMultiple
        ? []
        : undefined
    }
  },
  watch: {
    initialItems(val) {
      this.items = val
    },
    formValue(val) {
      this.$emit('input', val)
      this.$emit('change')
    },
    value(val) {
      this.formValue = val
    },
    selectedItems: {
      handler(val) {
        this.$emit('update:selectedItems', val)
      },
      deep: true
    }
  },
  methods: {
    searchItem(data) {
      if (this.endpoint) {
        const filterParams = { ...{ search: data.search }, ...this.extraFilter }
        const urlParams = new URLSearchParams(filterParams).toString()
        this.$axios.$get(`${data.endpoint}?${urlParams}`).then(response => {
          if (response.results && response.results.length) {
            this.items = response.results
          } else {
            this.items = []
          }
        })
      }
    },
    addItem(data) {
      const endpoint = data.endpoint
      const postData = this.deepCopy(this.extraPostData)
      postData[this.itemText] = data.add
      if (this.extraFilter.institution_type) {
        postData.institution_type = this.extraFilter.institution_type
      }
      this.$axios.$post(endpoint, postData).then(response => {
        if (this.allowMultiple) {
          this.formValue.push(response[this.itemValue])
        } else {
          this.formValue = response
        }
        this.items && this.items.push(response)
      })
    }
  }
}
