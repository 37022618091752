<template>
  <nav
    class="navbar is-transparent py-5 is-fixed-bottom box columns has-background-light-blue is-centered"
  >
    <div class="column is-10">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-4">
            <b>{{
              $store.getters['shoppingCart/shortlistedEmployerJobseekerCount']
            }}</b>
            {{ $t('jobSeekerList') }}
          </div>
          <div class="column has-text-centered is-4">
            <a @click="goToShortListPage">{{ $t('View Shortlisted list') }}</a>
          </div>
          <div class="column has-text-right is-4">
            <a class="button is-light" @click="clearAllList()">
              {{ $t('Clear all List') }}
            </a>
            <a class="button is-rounded is-success" @click="buyJobseeker()">
              {{ $t('Buy Now') }}
            </a>
          </div>
          <div class="column is-narrow"></div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import FormMixin from '@/mixins/veeValidate'
import { uniMultiply } from '@/helpers/icons'
export default {
  components: {},
  mixins: [FormMixin],
  icons: {
    uniMultiply
  },
  methods: {
    clearAllList() {
      if (this.$auth.user && this.$auth.user.employer_profile) {
        this.$axios.$post(`/api/v1/payment/hiring-package/clear/`).then(() => {
          this.$store.dispatch('shoppingCart/resetEmployerJobSeekerShortlist')
        })
      } else {
        this.$store.dispatch('shoppingCart/resetEmployerJobSeekerShortlist')
      }
    },
    buyJobseeker() {
      if (!this.$store.state.auth.loggedIn) {
        this.redirectAnonymousUser()
      } else {
        this.$axios
          .$post(`/api/v1/payment/hiring-package/payment-package/`)
          .then(response => {
            this.postInCart(response.package)
          })
      }
    },
    postInCart(packageId) {
      this.$axios
        .$post(`api/v1/payment/wishlist-item/`, { package: packageId })
        .then(() => {
          this.$router.push(
            this.localePath({
              name: 'payment-cart-summary'
            })
          )
        })
    },
    goToShortListPage() {
      if (!this.$store.state.auth.loggedIn) {
        this.redirectAnonymousUser()
      } else if (
        this.$store.state.auth.loggedIn &&
        this.$store.state.auth.user.employer_profile &&
        this.$store.state.auth.user.has_filled_initial_form
      ) {
        this.$router.push(
          this.localePath({
            name: 'employer-profile-id',
            params: { id: 'me' },
            query: { tab: 'shortlisted-jobseeker' }
          })
        )
      } else if (
        this.$store.state.auth.loggedIn &&
        this.$store.state.auth.user.employer_profile &&
        !this.$auth.user.has_filled_initial_form
      ) {
        this.setDialog({
          display: true,
          type: 'details',
          extraInfo: 'shortlist'
        })
      }
    },
    redirectAnonymousUser() {
      this.setDialog({ display: true, type: 'sign-in', extraInfo: 'shortlist' })
    }
  }
}
</script>
