<template>
  <div class="switch-container">
    <label class="switch pointer" :class="{ switchOn: inputVal }">
      <input v-model="inputVal" type="checkbox" @click="inputVal = !inputVal" />
      <div></div>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [Boolean, String],
      default: false
    }
  },
  data() {
    return {
      inputVal: !!this.value,
      backgroundColor: 'red'
    }
  },
  watch: {
    inputVal(value) {
      this.$emit('input', value)
    },
    value(value) {
      this.inputVal = value
    }
  }
}
</script>
<style>
.switch input {
  position: absolute;
  opacity: 0;
}

/**
 * 1. Adjust this to size
 */

.switch {
  display: inline-block;
  font-size: 20px; /* 1 */
  height: 1em;
  width: 2em;
  background: #bdb9a6;
  border-radius: 1em;
}

.switch div {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #fff;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
}

.switch input:checked + div {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.switchOn {
  background-color: rgb(72, 199, 116);
}
</style>
