const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const numberTypeChoices = [
  {
    value: 'Mobile',
    name: 'Mobile'
  },
  {
    value: 'Home',
    name: 'Home'
  },
  {
    value: 'Work',
    name: 'Work'
  }
]

const languageRatings = [
  {
    title: 'This is 1 star Rating',
    value: 1
  },
  {
    title: 'This is 2 star Rating',
    value: 2
  },
  {
    title: 'This is 3 star Rating',
    value: 3
  },
  {
    title: 'This is 4 star Rating',
    value: 4
  },
  {
    title: 'This is 5 star Rating',
    value: 5
  }
]

const preferredShitChoices = [
  {
    name: 'Regular',
    value: 'Regular'
  },
  {
    name: 'Morning',
    value: 'Morning'
  },
  {
    name: 'Day',
    value: 'Day'
  },
  {
    name: 'Evening',
    value: 'Evening'
  },
  {
    name: 'Flexible',
    value: 'Flexible'
  }
]

const durationChoices = [
  {
    name: 'Day',
    value: 'Day'
  },
  {
    name: 'Week',
    value: 'Week'
  },
  {
    name: 'Month',
    value: 'Month'
  },
  {
    name: 'Year',
    value: 'Year'
  }
]

const provinceBanner = [
  {
    id: 1,
    name: 'Province 1',
    banner_image: '/images/prov-1.jpg',
    sub_image: '/images/province-1.png',
    text: 'Jobs available in Koshi Pradesh'
  },
  {
    id: 2,
    name: 'Province 2',
    banner_image: '/images/prov-2.jpg',
    sub_image: '/images/province-2.png',
    text: 'Jobs available in Madhesh Pradesh'
  },
  {
    id: 3,
    name: 'Province 3',
    banner_image: '/images/prov-3.jpg',
    sub_image: '/images/province-3.png',
    text: 'Jobs available in Bagmati Province'
  },
  {
    id: 4,
    name: 'Province 4',
    banner_image: '/images/prov-4.jpg',
    sub_image: '/images/province-4.png',
    text: 'Jobs available in Gandaki Pradesh'
  },
  {
    id: 5,
    name: 'Province 5',
    banner_image: '/images/prov-5.jpg',
    sub_image: '/images/province-5.png',
    text: 'Jobs available in Lumbini Pradesh'
  },
  {
    id: 6,
    name: 'Province 6',
    banner_image: '/images/prov-6.jpg',
    sub_image: '/images/province-6.png',
    text: 'Jobs Available in Karnali Pradesh'
  },
  {
    id: 7,
    name: 'Province 7',
    banner_image: '/images/prov-7.jpg',
    sub_image: '/images/province-7.png',
    text: 'Jobs available in Sudurpashchim Pradesh'
  }
]

const accountChoices = [
  { name: 'Facebook' },
  { name: 'Instagram' },
  { name: 'Twitter' },
  { name: 'Youtube' },
  { name: 'linkedIn' }
]
export {
  months,
  numberTypeChoices,
  languageRatings,
  preferredShitChoices,
  durationChoices,
  provinceBanner,
  accountChoices
}
