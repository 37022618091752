<template>
  <div>
    <div
      :class="{ 'is-active': displayJobPostForm }"
      class="modal modal-z-index"
    >
      <div class="modal-background" @click="$emit('close')"></div>
      <div class="card modal-width modal-border modal-active">
        <header class="container modal-header-shadow card-header">
          <div
            class="column is-12 overflow-auto height-auto has-padding-left-40-desktop has-padding-right-40-desktop"
          >
            <div class="columns is-multiline is-mobile is-vcentered is-desktop">
              <div class="container column is-10">
                <div class="has-text-weight-bold is-size-5">
                  {{ $t('Post a Job') }}
                </div>
                <div class="is-size-7 has-text-grey">
                  {{ $t('Please fill your initial details to post a job') }}
                </div>
              </div>
              <div class="column is-2 modalbtn has-text-right">
                <span class="pointer" @click="$emit('close')">
                  <vue-icon-base
                    height="14"
                    width="14"
                    :path="$options.icons[$kc('uni-multiply')]"
                  >
                  </vue-icon-base>
                </span>
              </div>
            </div>
          </div>
        </header>
        <div
          id="topOfForm"
          class="card-content py-0 modal-height overflow-auto"
        >
          <div
            class="container has-padding-top-20 has-padding-left-40-desktop has-padding-right-40-desktop"
          >
            <validation-observer ref="observer">
              <div class="columns is-multiline is-mobile">
                <div v-if="nonFieldErrors.length" class="column is-12">
                  <vue-form-errors
                    :non-field-errors="nonFieldErrors"
                  ></vue-form-errors>
                </div>

                <div class="column is-6-desktop is-12-mobile pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Job Title')"
                    rules="required"
                  >
                    <div class="field">
                      <tool-tip text="Example: Waiter, Cook, Driver, more…">
                        <div class="is-flex">
                          <label class="label is-size-8 has-text-grey">
                            <vue-icon-base
                              height="14"
                              width="14"
                              :path="$options.icons[$kc('uni-briefcase')]"
                            >
                            </vue-icon-base>
                            {{ $t('Job Title') }}

                            <span class="has-text-danger">*</span> </label
                          ><vue-icon-base
                            height="14"
                            width="14"
                            :path="$options.icons[$kc('uni-question-circle')]"
                          >
                          </vue-icon-base></div
                      ></tool-tip>
                      <div class="control">
                        <vue-auto-complete
                          v-model="formValues.title"
                          :class="{
                            'is-danger': errors.length || fieldErrors.title
                          }"
                          extra-input-class="is-small"
                          endpoint="/api/v1/common/designations/"
                          :placeholder="$t('Job Title')"
                          :error="!!(errors.length || fieldErrors.title)"
                          item-text="name"
                          item-value="id"
                          allow-create
                          @updateSelectedItems="
                            templateName = $event.length && $event[0].name
                          "
                        ></vue-auto-complete>
                      </div>
                      <p class="field-error help is-danger my-0">
                        {{ errors[0] || fieldErrors.title }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div
                  class="column is-3-desktop is-12-mobile pb-0 is-top-paddingless-mobile"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Preferred Shift')"
                    rules="required"
                  >
                    <div class="field">
                      <label class="label is-size-8 has-text-grey">
                        <vue-icon-base
                          height="14"
                          width="14"
                          :path="$options.icons[$kc('uni-clock')]"
                        ></vue-icon-base>
                        {{ $t('Preferred Shift') }}
                        <span class="has-text-danger">*</span>
                      </label>
                      <div class="control">
                        <auto-complete
                          v-model="formValues.preferred_shift"
                          :class="{
                            'is-danger':
                              errors.length || fieldErrors.preferred_shift
                          }"
                          extra-input-class="is-small"
                          :items="$options.preferredShitChoices"
                          :error="
                            !!(errors.length || fieldErrors.preferred_shift)
                          "
                          item-text="name"
                          item-value="value"
                        ></auto-complete>
                      </div>
                      <p class="field-error help is-danger my-0">
                        {{ errors[0] || fieldErrors.preferred_shift }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div
                  class="column is-3-desktop is-12-mobile pb-0 is-top-paddingless-mobile"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('No of Candidates')"
                    rules="required|numeric|min_value:1"
                  >
                    <div class="field">
                      <label class="label is-size-8 has-text-grey">
                        <vue-icon-base
                          height="14"
                          width="14"
                          :path="$options.icons[$kc('uni-users-alt')]"
                        ></vue-icon-base>
                        {{ $t('No of Candidates') }}
                        <span class="has-text-danger">*</span>
                      </label>
                      <div class="control">
                        <input
                          v-model="formValues.vacancies"
                          :placeholder="$t('No of Candidates')"
                          :class="{
                            'is-danger': errors.length || fieldErrors.vacancies
                          }"
                          class="input is-small"
                          type="number"
                          min="1"
                        />
                      </div>
                      <p class="field-error help is-danger my-0">
                        {{ errors[0] || fieldErrors.vacancies }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div class="column is-12 py-0">
                  <label class="label is-size-8 has-text-grey">
                    <vue-icon-base
                      height="14"
                      width="14"
                      :path="$options.icons[$kc('uni-map-marker')]"
                    >
                    </vue-icon-base>
                    {{ $t('jobLocation') }}
                    <span class="has-text-danger">*</span>
                  </label>
                </div>
                <div class="column is-6-desktop is-12-mobile py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('District')"
                    rules="required"
                  >
                    <div class="field">
                      <div class="control">
                        <vue-auto-complete
                          :key="districtKey"
                          v-model="district"
                          :error="!!(errors.length || fieldErrors.district)"
                          endpoint="/api/v1/common/district/"
                          item-text="name"
                          extra-input-class="is-small"
                          :placeholder="$t('District')"
                          from="business"
                          item-value="id"
                          @updateSelectedItems="districtCounter += 1"
                        ></vue-auto-complete>
                      </div>
                      <p class="field-error help is-danger my-0">
                        {{ errors[0] || fieldErrors.district }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div class="column is-3-desktop is-12-mobile py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Municipality / VDC')"
                    rules="required"
                  >
                    <div class="field">
                      <div class="control">
                        <vue-auto-complete
                          :key="cityKey"
                          v-model="city"
                          endpoint="/api/v1/common/cities/"
                          :extra-filter="{ district: district }"
                          item-text="name"
                          extra-input-class="is-small"
                          :placeholder="$t('Municipality/VDC')"
                          item-value="id"
                          filter-by="default_name"
                          :disabled="!district"
                          :class="{
                            'is-danger': errors.length || fieldErrors.city
                          }"
                          :error="!!(errors.length || fieldErrors.city)"
                          @updateSelectedItems="cityCounter += 1"
                        ></vue-auto-complete>
                      </div>

                      <p class="field-error help is-danger my-0">
                        {{ errors[0] || fieldErrors.city }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div class="column is-3-desktop is-12-mobile py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Address')"
                    rules="required"
                  >
                    <div class="field">
                      <div class="control">
                        <vue-auto-complete
                          :key="locationKey"
                          v-model="formValues.job_locations"
                          :class="{
                            'is-danger':
                              errors.length || fieldErrors.job_locations
                          }"
                          extra-input-class="is-small"
                          :extra-post-data="{ city: city }"
                          :extra-filter="{ city: city }"
                          :disabled="!city"
                          allow-create
                          endpoint="/api/v1/common/locations/"
                          :placeholder="$t('Address')"
                          item-text="address"
                          item-value="id"
                          :error="
                            !!(errors.length || fieldErrors.job_locations)
                          "
                          @updateSelectedItems="
                            formValues.locationName =
                              $event.length && $event[0].address
                          "
                        ></vue-auto-complete>
                      </div>

                      <p class="field-error help is-danger my-0">
                        {{ errors[0] || fieldErrors.job_locations }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div v-show="false" class="column is-12 py-0">
                  <div class="columns is-multiline is-mobile">
                    <div class="column is-12-desktop is-12-mobile">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('Tags')"
                        rules="max_length_10"
                      >
                        <div class="field">
                          <label class="label is-size-8 has-text-grey">
                            <vue-icon-base
                              height="14"
                              width="14"
                              :path="$options.icons[$kc('uni-clock')]"
                            ></vue-icon-base>
                            {{ $t('Tags') }}
                            <span class="has-text-danger">*</span>
                          </label>
                          <div class="control">
                            <vue-auto-complete
                              v-model="formValues.tags"
                              add-item-title="tags"
                              item-text="name"
                              add-item-button
                              item-value="id"
                              endpoint="/api/v1/job/job-tag/"
                              allow-multiple
                              allow-create
                            ></vue-auto-complete>
                          </div>
                          <p class="field-error help is-danger my-0">
                            {{ errors[0] || fieldErrors.tags }}
                          </p>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div
                  class="column is-6-desktop is-12-mobile is-top-paddingless-desktop"
                >
                  <div class="columns is-multiline is-mobile">
                    <div
                      class="column is-3-desktop is-12-mobile is-top-paddingless-mobile"
                    >
                      <label class="label is-size-8 has-text-grey">
                        <vue-icon-base
                          height="14"
                          width="14"
                          :path="$options.icons[$kc('uni-money-bill')]"
                        ></vue-icon-base>
                        {{ $t('Offered Salary') }}
                        <span class="has-text-danger">*</span>
                      </label>
                    </div>
                    <div
                      class="column is-8-desktop is-6-mobile is-top-paddingless-mobile is-bottom-paddingless-mobile"
                    >
                      <label
                        class="is-size-8 has-padding-right-20-desktop radio"
                      >
                        <input v-model="isRange" type="radio" :value="false" />
                        {{ $t('Fixed') }}
                      </label>
                      <label class="radio is-size-8">
                        <input
                          v-model="isRange"
                          type="radio"
                          :value="true"
                          @change="offeredSalary.maximum = null"
                        />
                        {{ $t('Range') }}
                      </label>
                    </div>
                    <div
                      class="column is-12-mobile field is-horizontal is-top-paddingless-desktop is-bottom-paddingless"
                    >
                      <div class="field-body">
                        <div class="field is-expanded">
                          <div class="field has-addons">
                            <p class="control">
                              <a class="button is-static is-small">
                                {{ $t('NRs.') }}
                              </a>
                            </p>
                            <p class="control">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="$t('Offered Salary')"
                                rules="required|numeric|min_value:0"
                              >
                                <input
                                  v-model="offeredSalary.minimum"
                                  class="input is-small"
                                  min="0"
                                  :class="{
                                    'is-danger':
                                      errors.length || fieldErrors.minimum
                                  }"
                                  type="number"
                                  :placeholder="$t('e.g. 25000')"
                                />
                                <span class="field-error help is-danger my-0">
                                  {{ errors[0] || fieldErrors.minimum }}
                                </span>
                              </validation-provider>
                            </p>
                            <p class="control">
                              <a class="button is-static is-small">
                                {{ $t('salaryTo') }}
                              </a>
                            </p>
                            <p class="control is-expanded">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="$t('Maximum Salary')"
                                rules="numeric|min_value:0"
                              >
                                <input
                                  v-model="offeredSalary.maximum"
                                  class="input is-small"
                                  min="0"
                                  :class="{
                                    'is-danger':
                                      errors.length || fieldErrors.maximum
                                  }"
                                  :disabled="!isRange"
                                  type="number"
                                  :placeholder="$t('e.g. 25000')"
                                />
                                <span class="field-error help is-danger my-0">
                                  {{ errors[0] || fieldErrors.maximum }}
                                </span>
                              </validation-provider>
                            </p>
                            <p class="control">
                              <span class="select is-small is-multiple">
                                <select
                                  v-model="offeredSalary.unit"
                                  class="is-small"
                                >
                                  <option
                                    v-for="salaryUnit in salaryUnits"
                                    :id="salaryUnit.value"
                                    :key="salaryUnit.value"
                                    :value="salaryUnit.value"
                                  >
                                    {{ salaryUnit.text }}
                                  </option>
                                </select>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-3-desktop is-12-mobile py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Job Type')"
                    rules="required"
                  >
                    <div class="field">
                      <label
                        class="label is-size-8 has-text-grey has-padding-bottom-5-desktop"
                      >
                        <vue-icon-base
                          height="14"
                          width="14"
                          :path="$options.icons[$kc('uni-file-check')]"
                        ></vue-icon-base>
                        {{ $t('Job Type') }}
                        <span class="has-text-danger">*</span>
                      </label>
                      <div class="control">
                        <auto-complete
                          v-model="formValues.available_for"
                          :class="{
                            'is-danger':
                              errors.length || fieldErrors.available_for
                          }"
                          extra-input-class="is-small"
                          :items="$options.availableForChoices"
                          :error="
                            !!(errors.length || fieldErrors.available_for)
                          "
                          item-text="name"
                          item-value="value"
                        ></auto-complete>
                      </div>
                      <p class="field-error help is-danger">
                        {{ errors[0] || fieldErrors.available_for }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div class="column is-3-desktop is-12-mobile py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Job Level')"
                    rules="required"
                  >
                    <div class="field">
                      <label
                        class="label is-size-8 has-text-grey has-padding-bottom-5-desktop"
                      >
                        <vue-icon-base
                          height="14"
                          width="14"
                          :path="$options.icons[$kc('uni-sitemap')]"
                        ></vue-icon-base>
                        {{ $t('Job Level') }}
                        <span class="has-text-danger">*</span>
                      </label>
                      <div class="control">
                        <auto-complete
                          v-model="formValues.job_level"
                          :class="{
                            'is-danger': errors.length || fieldErrors.job_level
                          }"
                          :items="$options.jobLevelChoices"
                          extra-input-class="is-small"
                          :error="!!(errors.length || fieldErrors.job_level)"
                          item-text="name"
                          item-value="value"
                        ></auto-complete>
                      </div>
                      <p class="field-error help is-danger">
                        {{ errors[0] || fieldErrors.job_level }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div class="column is-12 py-0">
                  <label class="label is-size-8 has-text-grey">
                    <vue-icon-base
                      height="14"
                      width="14"
                      :path="$options.icons[$kc('uni-list-ul')]"
                    >
                    </vue-icon-base>
                    {{ $t('Job Description') }}
                  </label>
                  <vue-pell
                    :key="componentKey"
                    v-model="formValues.description"
                  ></vue-pell>
                </div>
              </div>
            </validation-observer>
          </div>
        </div>
        <footer class="container card-footer">
          <div
            class="container column is-12 has-text-right has-padding-right-40-desktop"
          >
            <button
              class="button cancel-button-width is-one-third is-outlined is-small is-employer"
              @click="$emit('close')"
              v-text="$t('Cancel')"
            ></button>
            <button
              v-if="!updateButton"
              class="button button-width is-jobseeker has-text-white has-text-weight-medium is-small"
              :disabled="disabled"
              @click.prevent="gotoJobSpecificationModal()"
              v-text="$t('Proceed')"
            ></button>
            <button
              v-if="updateButton"
              class="button button-width is-jobseeker modal-button has-text-white is-small"
              :disabled="disabled"
              @click.prevent="updateBasicInfo()"
              v-text="$t('Update')"
            ></button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import VueIconBase from '@/components/Commons/VueIconBase'
import FormMixin from '@/mixins/veeValidate'
import VueAutoComplete from '@/components/Commons/VueAutoComplete'
import AutoComplete from '@/components/Commons/AutoComplete'
import { extend } from 'vee-validate'
import { mapMutations } from 'vuex'
import {
  uniQuestionCircle,
  uniMultiply,
  uniBriefcase,
  uniMapMarker,
  uniClock,
  uniUsersAlt,
  uniMoneyBill,
  uniListUl,
  uniFileCheck,
  uniSitemap
} from '@/helpers/icons'
import VuePell from '@/components/Commons/VuePell'
import {
  availableForChoices,
  genderChoices,
  jobLevelChoices
} from '@/helpers/constants/jobseeker'
import { preferredShitChoices } from '@/helpers/constants/common'
import ToolTip from '~/components/ToolTip.vue'
export default {
  components: {
    VueAutoComplete,
    VueIconBase,
    AutoComplete,
    VuePell,
    ToolTip
  },
  middleware: 'employer',
  icons: {
    uniQuestionCircle,
    uniMultiply,
    uniBriefcase,
    uniMapMarker,
    uniClock,
    uniUsersAlt,
    uniMoneyBill,
    uniListUl,
    uniFileCheck,
    uniSitemap
  },
  mixins: [FormMixin],
  availableForChoices,
  genderChoices,
  jobLevelChoices,
  preferredShitChoices,
  props: {
    displayJobPostForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      disabled: false,
      formValues: {
        title: '',
        province: '',
        district: '',
        city: '',
        description: '',
        job_locations: '',
        vacancies: 1,
        preferred_shift: 'Regular',
        available_for: 'full_time',
        job_level: 'entry_level',
        tags: []
      },
      jobDetails: {},
      componentKey: 1,
      districtKey: 1,
      slug: '',
      updateButton: false,
      displayJobSpecificationForm: false,
      cityKey: 1,
      locationKey: 1,
      templateName: '',
      isRange: false,
      offeredSalary: {
        currency: 'NRs',
        operator: 'Equals',
        minimum: '',
        maximum: null,
        unit: 'Monthly'
      },
      templateDetails: {},
      templateCounter: 0,
      provinceCounter: 0,
      cityCounter: 0,
      districtCounter: 0,
      province: '',
      district: '',
      city: '',
      salaryUnits: [
        { value: 'Hourly', text: this.$t('Hourly') },
        { value: 'Daily', text: this.$t('Daily') },
        { value: 'Weekly', text: this.$t('Weekly') },
        { value: 'Monthly', text: this.$t('Monthly') },
        { value: 'Yearly', text: this.$t('Yearly') }
      ]
    }
  },
  watch: {
    templateName: {
      handler(val) {
        this.$emit('jobName', val)
        if (this.templateCounter !== 1) {
          if (val) {
            this.getTemplateDetails(val)
          } else {
            this.componentKey += 1
            this.formValues.vacancies = 1
            this.formValues.available_for = 'full_time'
            this.formValues.job_level = 'entry_level'
            this.formValues.preferred_shift = 'Regular'
          }
        }
      }
    },
    'formValues.vacancies'(val) {
      this.$emit('vacancy', val)
    },
    isRange(val) {
      if (!val) {
        this.offeredSalary.maximum = null
      }
    },
    province: {
      handler(val) {
        if (this.provinceCounter > 0) {
          this.district = ''
          this.districtKey += 1
          this.formValues.job_locations = ''
          this.locationKey += 1
          this.city = ''
          this.cityKey += 1
        }
      },
      immediate: true,
      deep: true
    },
    city: {
      handler(val) {
        if (this.cityCounter) {
          this.formValues.job_locations = ''
          this.locationKey += 1
        }
      },
      immediate: true,
      deep: true
    },
    district: {
      handler(val) {
        if (this.districtCounter) {
          this.city = ''
          this.cityKey += 1
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.fetchData()
    this.addMaxLengthvalidator()
  },
  methods: {
    ...mapMutations({
      setCurrentJobSlug: 'job/setCurrentJobSlug',
      setCurrentJobDetail: 'job/setCurrentJobDetail'
    }),
    fetchData() {
      if (document.URL.toString().includes('slug')) {
        this.slug = document.URL.split('=')[1].split('&')[0]
        this.updateButton = true
        this.$axios
          .$get(`/api/v1/job/${this.slug}/basic-info-new/`)
          .then(response => {
            this.jobDetails = response
            this.populateFormValues(response)
          })
      }
    },
    addMaxLengthvalidator() {
      extend('max_length_10', {
        validate: value => {
          let validation = true
          validation = validation && value.length < 11
          return validation
        },
        message: `{_field_} ${this.$t('field must be less than or equal 10')}`
      })
    },
    getTemplateDetails(templateName) {
      if (this.slug === '') {
        this.$axios
          .get(`/api/v1/job/templates/?search=${templateName}`)
          .then(response => {
            this.templateDetails =
              response.data.results.length && response.data.results[0]
            this.populateTemplateDetails()
          })
      }
    },
    populateTemplateDetails() {
      this.componentKey += 1
      this.formValues.job_level =
        this.templateDetails.job_level || 'entry_level'
      this.formValues.available_for =
        (this.templateDetails.available_for &&
          this.templateDetails.available_for[0]) ||
        'full_time'
      this.offeredSalary = this.templateDetails.offered_salary || {
        currency: 'NRs',
        operator: 'Equals',
        minimum: '',
        maximum: null,
        unit: 'Monthly'
      }
      this.formValues.description = this.templateDetails.description || ''
      this.isRange = !!this.get(this.templateDetails, 'offered_salary.maximum')
    },
    populateFormValues(response) {
      this.componentKey += 1
      this.formValues = { ...response }
      this.formValues.available_for = response.available_for
        ? response.available_for[0]
        : ''
      this.formValues.job_locations = response.job_locations[0]
      this.formValues.locationName = response.job_locations[0].address
      this.province = response.location_info.province || ''
      this.district = response.location_info.district || ''
      this.city = response.location_info.city || ''
      this.job_level = response.job_level || ''
      this.offeredSalary = response.offered_salary || {
        currency: 'NRs',
        operator: 'Equals',
        minimum: '',
        maximum: null,
        unit: 'Monthly'
      }
      this.isRange = !!this.get(response, 'offered_salary.maximum')
    },
    constructFormValues() {
      if (!this.isRange) {
        this.offeredSalary.maximum = null
      }
      return {
        organization: this.$auth.user.related_organizations[0]
          ? this.$auth.user.related_organizations[0].id
          : null,
        title: this.formValues.title,
        job_locations: [this.formValues.job_locations],
        vacancies: this.formValues.vacancies,
        description: this.formValues.description,
        offered_salary: this.offeredSalary,
        preferred_shift: this.formValues.preferred_shift,
        job_level: this.formValues.job_level,
        available_for: [this.formValues.available_for],
        tags: this.formValues.tags
      }
    },
    async gotoJobSpecificationModal() {
      if (await this.validateAllFields()) {
        this.disabled = true
        this.$axios
          .$post(`/api/v1/job/basic-info-new/`, this.constructFormValues())
          .then(response => {
            this.$emit('close')
            this.notifyUser(this.$t('Successfully updated Basic Information'))
            history.pushState(
              {},
              '',
              this.localePath({
                name: 'job-post',
                query: {
                  slug: response.slug,
                  step: '2'
                }
              })
            )
            this.$emit('open')
          })
          .catch(error => {
            if (error.response.status === 412) {
              this.notifyError('Please fill up the Initial form first.')
            }
            this.getError(error)
            if (this.nonFieldErrors.length) {
              document
                .getElementById('topOfForm')
                .scrollIntoView({ block: 'start', behavior: 'smooth' })
            }
          })
      }
    },
    async updateBasicInfo() {
      if (await this.validateAllFields()) {
        this.disabled = true
        this.fieldErrors = {}
        const formValues = this.constructFormValues()
        this.$axios
          .$put(
            `/api/v1/job/${this.formValues.slug}/basic-info-new/`,
            formValues
          )
          .then(response => {
            this.$emit('finalPreview', false)
            this.$emit('basicInfo', {
              formValues: this.formValues,
              offeredSalary: this.offeredSalary,
              jobName: this.templateName
            })
            this.setCurrentJobSlug(this.formValues.slug)
            this.setCurrentJobDetail(response)
            this.notifyUser(this.$t('Successfully updated Basic Information'))
            this.$emit('close')
            history.pushState(
              {},
              '',
              this.localePath({
                name: 'job-post',
                query: {
                  slug: this.formValues.slug,
                  step: '2'
                }
              })
            )
            this.$emit('open')
          })
          .catch(error => {
            this.getError(error)
            if (this.nonFieldErrors.length) {
              document
                .getElementById('topOfForm')
                .scrollIntoView({ block: 'start', behavior: 'smooth' })
            }
          })
      }
    }
  }
}
</script>
<style scoped>
@media only screen and (max-width: 768px) {
  .modal-width {
    max-height: 90vh;
    bottom: 0;
    position: absolute;
  }
  .modal-height {
    height: 25rem;
    overflow-x: hidden;
  }
  .cancel-button-width {
    width: 28%;
  }
  .button-width {
    width: 70%;
  }
}
@media only screen and (min-width: 769px) {
  .modal-width {
    position: absolute;
    height: auto;
  }
  .modal-height {
    height: 27rem;
    overflow: auto;
  }
}
.is-size-8 {
  font-size: 0.85rem;
}
</style>
