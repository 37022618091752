const mockedApis = [
  // {
  //   urlPattern: new RegExp('/api/v1/auth/(?<slug>get-token)/'),
  //   method: 'post',
  //   status: 400,
  //   response201: () => {
  //     return {
  //       status: 201
  //     }
  //   },
  //   response400: ctx => {
  //     return {
  //       phone_number: [`This field is wrufesh.${ctx.urlParams.slug}`],
  //       password: ['This field is wrufesh.']
  //     }
  //   }
  // },
]

export default mockedApis
