const jobLevelChoices = [
  {
    name: 'Top Level',
    value: 'top_level'
  },
  {
    name: 'Senior Level',
    value: 'senior_level'
  },
  {
    name: 'Mid Level',
    value: 'mid_level'
  },
  {
    name: 'Entry Level',
    value: 'entry_level'
  }
]
const workingStatusChoices = [
  {
    name: 'Actively Seeking Employment',
    value: 'actively_seeking'
  },
  {
    name: 'Currently Working',
    value: 'currently_working'
  },
  {
    name: 'Available for Employment',
    value: 'available'
  }
]

const availableForChoices = [
  {
    name: 'Full Time',
    value: 'full_time'
  },
  {
    name: 'Part Time',
    value: 'part_time'
  },
  {
    name: 'Contractual',
    value: 'contractual'
  },
  {
    name: 'Freelancing',
    value: 'freelancing'
  },
  {
    name: 'Internship',
    value: 'internship'
  },
  {
    name: 'Volunteer',
    value: 'volunteer'
  },
  {
    name: 'Temporary',
    value: 'temporary'
  },
  {
    name: 'Traineeship',
    value: 'traineeship'
  }
]

const genderChoices = [
  {
    name: 'Female',
    value: 'Female'
  },
  {
    name: 'Male',
    value: 'Male'
  },
  { name: 'Other', value: 'Other' }
]

const maritalStatusChoices = [
  {
    name: 'Married',
    value: 'Married'
  },
  {
    name: 'Unmarried',
    value: 'Unmarried'
  }
]

const workExperienceChoices = [
  { name: 'No Experience', value: 0 },
  { name: '1 year', value: 12 },
  { name: '2 years', value: 24 },
  { name: '3 years', value: 36 },
  { name: '4 years', value: 48 },
  { name: '5 years', value: 60 },
  { name: '6 years', value: 72 },
  { name: '7 years', value: 84 },
  { name: '8 years', value: 96 },
  { name: '9 years', value: 108 },
  { name: '10 years or more', value: 120 }
]

const salaryRangeChoices = [
  {
    name: 'Below 15,000',
    value: {
      currency: 'NPR',
      operator: 'Below',
      minimum: 0,
      maximum: 15000
    }
  },
  {
    name: '15,001 - 25,000',
    value: {
      currency: 'NPR',
      operator: 'Equals',
      minimum: 15001,
      maximum: 25000
    }
  },
  {
    name: '25,001 - 35,000',
    value: {
      currency: 'NPR',
      operator: 'Equals',
      minimum: 25001,
      maximum: 35000
    }
  },
  {
    name: '35,001 - 45,000',
    value: {
      currency: 'NPR',
      operator: 'Equals',
      minimum: 35001,
      maximum: 45000
    }
  },
  {
    name: '45,001 - 55,000',
    value: {
      currency: 'NPR',
      operator: 'Equals',
      minimum: 45001,
      maximum: 55000
    }
  },
  {
    name: 'Above 55,000',
    value: {
      currency: 'NPR',
      operator: 'Above',
      minimum: 55000,
      maximum: null
    }
  }
]
const numberTypeChoices = [
  {
    value: 'Mobile',
    name: 'Mobile'
  },
  {
    value: 'Home',
    name: 'Home'
  },
  {
    value: 'Work',
    name: 'Work'
  }
]

const relationChoices = [
  {
    value: 'Father',
    name: 'Father'
  },
  {
    value: 'Mother',
    name: 'Mother'
  },
  {
    value: 'Friend',
    name: 'Friend'
  }
]

export {
  jobLevelChoices,
  workingStatusChoices,
  availableForChoices,
  genderChoices,
  maritalStatusChoices,
  workExperienceChoices,
  numberTypeChoices,
  relationChoices,
  salaryRangeChoices
}
