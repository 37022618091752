import VueCardTitle from '@/components/Commons/VueCardTitle'
import Vue from 'vue'
import { mapMutations } from 'vuex'

Vue.mixin({
  components: { VueCardTitle },
  methods: {
    ...mapMutations({
      notifyUser: 'notifyUser',
      notifyError: 'notifyError',
      setDialog: 'setLoginDialog'
    }),
    get(o, p) {
      if (typeof p === 'string') {
        p = p.split('.')
      }
      const reducer = (xs, x) => {
        if (xs) {
          return xs[x] ? xs[x] : null
        }
      }
      return p.reduce(reducer, o)
    },
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    // $kc ---> kebab to Camel Case
    $kc(str) {
      if (str)
        return str.replace(/([-_][a-z])/g, group =>
          group.toUpperCase().replace('-', '').replace('_', '')
        )
    },
    logout() {
      this.$auth.logout().then(() => {
        this.$store.dispatch('shoppingCart/resetEmployerJobSeekerShortlist')
        this.$router.push(this.localePath('index'))
        this.notifyUser('Successfully Logged Out')
        this.showNavbarItems = false
      })
    }
  }
})
