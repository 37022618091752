import mockedApis from './apiMock.js'

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const MockAdapter = require('axios-mock-adapter')

const mock = new MockAdapter()

mockedApis.forEach(mok => {
  mock[`on${capitalize(mok.method)}`](mok.urlPattern).reply(config => {
    if (mok.urlPattern instanceof RegExp) {
      const regMatch = mok.urlPattern.exec(config.url)
      if (regMatch) {
        config.urlParams = regMatch.groups
      }
    }
    return [mok.status, mok[`response${mok.status}`](config)]
  })
})

function setAxiosAdapter(config, axios) {
  const urlPatterns = mockedApis

  const matched = urlPatterns.find(obj => {
    if (obj.method === config.method) {
      if (obj.urlPattern instanceof RegExp) {
        const regMatch = obj.urlPattern.exec(config.url)
        return !!regMatch
      } else if (typeof obj.urlPattern === 'string') {
        return obj.urlPattern === config.url
      }
    }
  })
  if (matched) {
    config.adapter = mock.adapter()
  } else {
    config.adapter = axios.defaults.adapter
  }
}

export default setAxiosAdapter
