function get(o, p) {
  const reducer = (xs, x) => {
    if (xs) {
      return xs[x] ? xs[x] : null
    }
  }
  return p.reduce(reducer, o)
}

export const state = () => ({
  // TODO place following one to another store
  homePageFilterFor: null,
  collectionResponses: {}
})

// TODO change name of file and mutation(remove collection)
export const mutations = {
  deleteCollection(state, index) {
    const likeJob = state.collectionResponses.likedJobsCollection.results
    likeJob.splice(index, 1)
  },
  setCollectionData(state, payload) {
    const newData = {}
    newData[payload[0]] = {
      ...(state.collectionResponses[payload[0]] || {}),
      ...payload[1]
    }
    state.collectionResponses = {
      ...state.collectionResponses,
      ...newData
    }
  },
  modifyLeaf(state, payload) {
    // const collectionName = payload.collectionName
    let path = payload.path
    const data = payload.data
    if (typeof path === 'string') {
      path = path.split('.')
    }
    if (typeof get(state.collectionResponses, path) !== 'undefined') {
      const lastKey = path.pop()

      get(state.collectionResponses, path)[lastKey] = data
    }
  },

  changeHomePageFilterFor(state, value) {
    state.homePageFilterFor = value
  }
}
