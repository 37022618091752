import setAxiosAdapter from '@/helpers/axiosMock'

export default function ({ $axios, redirect, store, app, error }) {
  // let setAxiosAdapter = null

  // if (process.env.NODE_ENV === 'development') {
  //   import('@/helpers/axiosMock').then(mod => {
  //     setAxiosAdapter = mod.default
  //   })
  // }

  $axios.defaults.baseURL = process.env.API_BASE_URL
  $axios.onRequest(config => {
    config.headers.common['Accept-Language'] = app.i18n.locale

    if (process.env.NODE_ENV === 'development') {
      setAxiosAdapter(config, $axios)
    }
  })
  $axios.onError(e => {
    if (e.response && ![412, 428, 400].includes(e.response.status)) {
      const errorMessage = e.response.data.detail || 'Something went wrong.'
      if (e.response.status === 401) {
        app.$auth.logout()
        if (process.client) {
          store.commit('notifyError', errorMessage)
        } else {
          redirect(`/sign-in?errorMessage=${errorMessage}`)
        }
      } else if (e.response.status === 429) {
        store.commit('notifyError', errorMessage)
      } else {
        return error({
          statusCode: e.response.status,
          message: 'Nuxt server error'
        })
      }
    }
  })
}
