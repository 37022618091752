<template>
  <vue-error :status-code="error.statusCode.toString()"></vue-error>
</template>

<script>
import VueError from '@/components/VueError'
export default {
  components: {
    VueError
  },
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head() {
    return {
      title: `${this.error.statusCode} Error`
    }
  }
}
</script>
