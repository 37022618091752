export const state = () => ({
  snackBar: { text: '', color: '', display: false },
  screen: null,
  loginDialog: {
    display: false,
    type: '',
    extraInfo: ''
  },
  project: null,
  pricingData: {
    job_price_setting: null,
    quantity: null
  },
  showChart: false,
  index: 0
})

export const getters = {
  getIndex(state) {
    return state.index
  },
  getChart(state) {
    return state.showChart
  },
  getPricingData(state) {
    return state.pricingData
  },
  getSnackbar(state) {
    return state.snackBar
  },
  getScreen(state) {
    return state.screen
  },
  getLoginDialog(state) {
    return state.loginDialog
  },
  getProject(state) {
    return state.project
  }
}

export const mutations = {
  setIndex(state, value) {
    state.index = value
  },
  setChart(state, value) {
    state.showChart = value
  },
  setPricingData(state, value) {
    state.pricingData = value
  },
  setProject(state, value) {
    state.project = value
  },
  setScreen(state, value) {
    state.screen = value
  },
  setLoginDialog(state, data) {
    state.loginDialog = data
  },
  notifyUser(state, text) {
    state.snackBar = {
      text,
      color: 'is-success',
      display: true
    }
  },
  notifyError(state, text) {
    state.snackBar = {
      text,
      color: 'is-danger',
      display: true
    }
  },
  hideSnackbar(state) {
    state.snackBar = {
      text: '',
      color: '',
      display: false
    }
  }
}
