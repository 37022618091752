<template>
  <div class="has-width-55 has-width-85">
    <div class="columns px-5 pb-5 is-multiline card is-shadowless">
      <div class="column is-12 has-text-centered">
        <img
          height="190"
          width="170"
          src="@/static/images/brand/logo.svg"
          alt="rojgari logo"
        />
      </div>
      <div class="column card is-5 is-shadowless has-text-centered">
        <vue-icon-base
          height="75"
          width="75"
          :path="$options.icons[$kc('uni-user')]"
        >
        </vue-icon-base>
        <div class="mt-4">
          <button class="button is-jobseeker is-normal" @click="gotoSurvey()">
            {{ $t("I'm Looking For Job") }}
          </button>
        </div>
        <div class="mt-4 is-size-8">
          {{
            $t('Click above to register yourself as Job seeker and find Job')
          }}
        </div>
      </div>
      <div class="column is-2 has-text-centered px-0">
        <div class="has-vertical-divider"></div>
      </div>
      <div class="column card is-shadowless is-5 has-text-centered">
        <vue-icon-base
          height="75"
          width="75"
          :path="$options.icons[$kc('uni-building')]"
        >
        </vue-icon-base>
        <div class="mt-4">
          <button
            class="button is-employer is-normal"
            @click="openEmployerSignup()"
          >
            {{ $t("I'm Looking For Job Seeker") }}
          </button>
        </div>
        <div class="mt-4 is-size-8">
          {{
            $t(
              'Click above to register yourself as Employer and find Job Seeker'
            )
          }}
        </div>
      </div>
    </div>
    <vue-snackbar></vue-snackbar>
  </div>
</template>

<script>
import VueSnackbar from '@/components/Commons/VueSnackbar'
import VueIconBase from '@/components/Commons/VueIconBase'
import { uniUser, uniBuilding } from '@/helpers/icons'

export default {
  components: {
    VueSnackbar,
    VueIconBase
  },
  icons: {
    uniUser,
    uniBuilding
  },
  data() {
    return {}
  },
  methods: {
    gotoSurvey() {
      this.$emit('close')
      this.$router.push(
        this.localePath({
          name: 'jobseeker-registration'
        })
      )
    },
    openEmployerSignup() {
      this.$emit('close')
      this.setDialog({ display: true, type: 'sign-up' })
    }
  }
}
</script>

<style scoped>
.has-vertical-divider {
  border-left: 2px solid rgb(228, 227, 227);
  height: 200px;
  left: 50%;
  position: absolute;
  margin-left: -10;
}
.is-size-8 {
  font-size: 0.85rem;
}
@media screen and (min-width: 800px) {
  .has-width-55 {
    width: 55%;
  }
}
@media screen and (max-width: 800px) {
  .has-width-85 {
    width: 85%;
  }
}
</style>
