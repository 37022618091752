var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-background-error-color has-page-height"},[_c('div',{staticClass:"container has-padding-left-20-mobile has-padding-right-20-mobile"},[_c('div',{staticClass:"is-16 has-padding-top-60"},[_c('a',{on:{"click":function($event){return _vm.$router.push('/')}}},[_c('img',{attrs:{"height":"190","width":"190","src":require("@/static/images/brand/rojgari-white.svg"),"alt":"rojgari logo"}})])]),_vm._v(" "),_c('div',{staticClass:"columns has-padding-top-20 is-vcentered has-text-weight-bold has-text-white"},[_c('div',{staticClass:"column is-7"},[_c('div',{staticClass:"content is-size-1"},[_vm._v("\n          "+_vm._s(_vm.$t(
              ("" + (_vm.get(_vm.$options.config, [_vm.statusCode, 'warning']) ||
                (_vm.statusCode + " Error")))
            ))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"has-padding-bottom-30 has-text-weight-normal is-size-5"},[_vm._v("\n          "+_vm._s(_vm.$t(
              ("" + (_vm.get(_vm.$options.config, [_vm.statusCode, 'message']) ||
                (_vm.statusCode + " Error")))
            ))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"column is-left-paddingless is-12"},[_c('div',{staticClass:"field has-addons has-text-centered-mobile"},[_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"input is-outlined is-medium",attrs:{"placeholder":_vm.$t('search')},domProps:{"value":(_vm.searchQuery)},on:{"change":_vm.gotoJobSearchPage,"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}})]),_vm._v(" "),_c('div',{staticClass:"control"},[_c('a',{staticClass:"button is-medium",on:{"click":_vm.gotoJobSearchPage}},[_c('vue-icon-base',{attrs:{"height":"16","width":"16","path":_vm.$options.icons[_vm.$kc('uni-search')]}})],1)])])])]),_vm._v(" "),_c('div',{staticClass:"column is-vcentered is-hidden-mobile is-5"},[_c('img',{attrs:{"height":"400","width":"400","src":_vm.$options.config[_vm.statusCode]
              ? require(("@/static/images/" + _vm.statusCode + ".svg"))
              : '',"alt":(_vm.statusCode + " errorImage")}})])]),_vm._v(" "),_c('div',{staticClass:"has-margin-top-50 has-text-centered-mobile"},[_c('span',{staticClass:"has-margin-right-5"},[_c('button',{staticClass:"button is-big is-jobseeker",on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("\n          "+_vm._s(_vm.$t('homepage'))+"\n        ")])]),_vm._v(" "),_c('span',{},[_c('button',{staticClass:"button is-big is-error-color is-inverted is-outlined",on:{"click":function($event){return _vm.$router.push('/contact')}}},[_vm._v("\n          "+_vm._s(_vm.$t('contactUs'))+"\n        ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }