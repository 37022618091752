<template>
  <auto-complete
    v-model="formValue"
    :items="items"
    :title="title"
    :disabled="disabled"
    :endpoint="endpoint"
    :add-item-button="addItemButton"
    :add-item-title="addItemTitle"
    :item-text="itemText"
    :item-value="itemValue"
    :allow-create="allowCreate"
    :allow-multiple="allowMultiple"
    :placeholder="placeholder"
    :error="error"
    :limit-require="limitRequire"
    :from="from"
    :show-selected-items="showSelectedItems"
    :extra-input-class="extraInputClass"
    :filter-by="filterBy"
    :border="border"
    :showSearchItem="showSearchItem"
    @search="searchItem"
    @enterKeyOutput="$emit('enterKeyOutput')"
    @add="addItem"
    @updateSelectedItems="$emit('updateSelectedItems', $event)"
    :showSearchIcon="showSearchIcon"
    :showDropDownIcon="showDropDownIcon"
  ></auto-complete>
</template>
<script>
import AutoComplete from '@/components/Commons/AutoComplete'
import AutoCompleteWrapper from '@/mixins/autoCompleteWrapper'
export default {
  components: { AutoComplete },
  mixins: [AutoCompleteWrapper],
  props: {
    showDropDownIcon: {
      type: Boolean,
      default: false
    },
    showSearchIcon: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    from: {
      type: String,
      default: ''
    },
    limitRequire: {
      type: Boolean,
      default: true
    },
    addItemButton: {
      type: Boolean,
      default: false
    },
    addItemTitle: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true
    },
    showSearchItem: {
      type: Boolean,
      default: false
    }
  }
}
</script>
